import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Flex,
    useColorMode
  } from '@chakra-ui/react'
//   import ReactMarkdown from 'react-markdown';
  import Markdown from 'react-markdown';
  import { GoChevronLeft, GoChevronRight } from "react-icons/go";
  import "../Table/PastPreview.css"

const ContractModal = ({isOpen,onClose,Review,FileName}) => {
    const [scrollBehavior, setScrollBehavior] = React.useState('inside')
   const {colorMode} =useColorMode()

  return (
    <div>
      
      <Modal  size={"6xl"} isOpen={isOpen} onClose={onClose} scrollBehavior={scrollBehavior}>
      <GoChevronLeft size={24}  />
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className='modal-header'>
            <Flex>
          <Box _hover={{ bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer", borderRadius:     "100vh" }} p={"7px"}>
             <GoChevronLeft size={24} onClick={onClose} className='arrow-pre' 
             />
             </Box>
             { FileName.split('.')[0].replace(/(\.[^/.]+)+$/, "")  }

            </Flex>
            </ModalHeader>
          <ModalBody>
          <Box p={4}>
          <Markdown>{Review
           .replace(/\n/g, '\n\n')
           .replace(/\n\*\*Question/g, '\n---\n**Question')
           .replace(/(Question \d+:.*?)\?/g, '**$1?**') }

          </Markdown>
        </Box>
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default ContractModal
