import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // 595005852749-g22je9uv9po3ppk69v82l6riokq3e5ur.apps.googleusercontent.com
    <Router>
        <Provider store={store}>
    <React.StrictMode> 
        <ChakraProvider>
             {/* <GoogleOAuthProvider clientId= "1089540494300-4udaf3d53v128fhdhjecrn586bfk3bfc.apps.googleusercontent.com"> */}
            {/* <GoogleOAuthProvider clientId= "595005852749-g22je9uv9po3ppk69v82l6riokq3e5ur.apps.googleusercontent.com"> */}
            <GoogleOAuthProvider clientId= "1062042962594-5mjoegj20ll0vf1idb4otn8o6b5p0qd0.apps.googleusercontent.com">
            <App />
            </GoogleOAuthProvider>
            
        </ChakraProvider>
    </React.StrictMode>
    </Provider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
