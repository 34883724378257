import React, { useEffect, useMemo, useState } from "react";
import { DEALS_COLUMNS } from "./Columns";
import { useTable, usePagination, useRowSelect } from "react-table";
import {
  Box,
  Button,
  Flex,
  SkeletonText,
  Table,
  TableContainer,
  Tbody, 
  Td,
  Text,
  Textarea,
  Th,
  Thead, 
  Tr,
  useColorMode,
  useToast,
  InputGroup,
  InputLeftElement,

  Input,
  filterValue,
  InputRightElement,
  filter,
  Tag,
  Spinner,
  Card,
  TagLabel,
  TagCloseButton


} from "@chakra-ui/react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import { BsPersonPlusFill } from "react-icons/bs";

import Checkbox from "./Checkbox";
import { IoMdTrash } from "react-icons/io";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { BsBuildingFillAdd } from "react-icons/bs";
import { AddIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { useParams } from "react-router-dom";
import '../Table/Deals.css'

const DealsTable = () => {
      const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
      const token = localStorage.getItem("token");
      console.log(token);
      const toast = useToast();
     const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const columns = useMemo(() => DEALS_COLUMNS, []);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [Deals_Data, setDeals_Data] = useState([]);
  const [isOpenPasswordChangeDialogBox, setIsOpenPasswordChangeDialogBox] =useState(false);
  const [ispasswordChange, setIspasswordChange] = useState(false);
  const [password, setPassword] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [editedRowData, setEditedRowData] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [lastTapTime, setLastTapTime] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
 
  const [pageIndex, setPageIndex] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return parseInt(params.get('page')) || 1;
}); // Initialize pageIndex state
 
const [filterValue, setFilterValue] = useState("");
  console.log("filter value",filterValue)
  const [isOpenInfluencerFormModal, setIsOpenInfluencerFormModal] =
    useState(false);


  const [isOpenCompanyFormModal, setIsOpenCompanyFormModal] = useState(false);

  const [inputTalentValue, setInputTalentValue] = useState("");
  const [isTalentSearchLoading, setIsTalentSearchLoading] = useState(false);
  const [filteredTalentData, setFilteredTalentData] = useState([]);
  const {page :subpage} =useParams()
  console.log(subpage,"sub page ");

  const handleClick = () => setShowPassword(!showPassword);

  const [errors, setErrors] = useState({
    password: "",
  });

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    gotoPage,
    page,
    state: {  pageSize },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: Deals_Data,
      initialState: { pageIndex: 1, pageSize: 10 },
      manualPagination: true,
      pageCount,
    },
    // useSortBy,
    usePagination,
    useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => {
    //     return [
    //       {
    //         id: "selection",
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <Checkbox {...getToggleAllRowsSelectedProps()} />
    //         ),
    //         Cell: ({ row }) => (
    //           <Checkbox {...row.getToggleRowSelectedProps()} />
    //         ),
    //       },
    //       ...columns,
    //     ];
    //   });
    // }

  );

  const nextPage = () => {
    console.log("Next page clicked");
    console.log("Current pageIndex:", pageIndex);
    console.log("Total pageCount:", pageCount);
  
    if (pageIndex < pageCount) {
      const newIndex=pageIndex
      
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('page', (pageIndex+1));
        window.history.pushState({}, '', currentUrl.toString());
        // window.location.href = currentUrl.toString();
        setPageIndex(pageIndex+1);
        console.log("Current pageIndex:", pageIndex);
    }
  
   
  };
  

  // useEffect(()=>{

  //   const currentUrl =new URL(window.location.href)
  //   console.log(currentUrl,"urlcurrent");
  //   currentUrl.searchParams.set('page', pageIndex);
  //   window.location.href = currentUrl.toString();
  // },[pageIndex])

  
  const previousPage = () => {
    console.log("previous page clicked");
    if (pageIndex > 1) {
      console.log("pre page clicked");
      console.log("Current pageIndex:", pageIndex);
      console.log("Total pageCount:", pageCount);
      // const newIndex=pageIndex
    
      const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('page', (pageIndex-1));
        window.history.pushState({}, '', currentUrl.toString()); 
        setPageIndex(pageIndex-1);
    }
  
  };
  

  console.log("Current Page Index::::::::::::", pageIndex);
  console.log("Total Page Count::::::::::::::", pageCount);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');

    console.log('Page parameter from URL:', page);
    localStorage.setItem("page",page)

    // You can now use `page` in your component logic
}, [pageIndex]);


  const fetchDealsData = async () => {
    console.log("hello");
 
    try {
      const talentParams = `talent_name=${filterValue}`

      const response = await fetch(BASE_URL+(talentParams ? `/deals/?${talentParams}&page=${pageIndex  }&page_size=${pageSize}`: `/deals/?page=${pageIndex }&page_size=${pageSize}&ordering=-created_ts`), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = '/login';
        } else {
          throw new Error('Failed to fetch data');
        }
      }
  
      const data = await response.json();
      console.log(data,'dtaaaaaaadealss');
      setDeals_Data(data.Deals_data); // Assuming data.results is the array of deals
      setPageCount(data?.paginator?.total_pages); // Update pageCount based on API response
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
  
      toast({
        title: "Fetch Error.",
        description: "Failed to fetch Deals_data details",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
  
      console.error("Error:", error);
    }
  };


  useEffect(() => {
    const timer = setTimeout(async () => {
      await fetchDealsData();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [pageIndex, pageSize,filterValue]);

 useEffect(() => {
        if(inputTalentValue.trim() !== '') {
            const timeout = setTimeout(() => {
                fetchTalents();
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [inputTalentValue]);

  useEffect(() => {
    // handleEditRow()
    setIsEditing(null);
    setEditedRowData(null);
  }, []);

  //   .................................

  useEffect(() => {
    if (selectedFlatRows.length > 1) {
      setIspasswordChange(false);
    } else {
      setIspasswordChange(true);
    }
  }, [selectedFlatRows]);

  // .....................

  const handleCellBlur = () => {
    // Auto-save when clicking outside the cell
    if (isEditing) {
      setIsEditing(null);
      setEditingCell(null);
     
    }
  };

  const handleCellClick = (row, cell) => {
    // if (cell.column) {
    //   setIsEditing(row.original.id);
    //   setEditedRowData(row.original);
    //   setEditingCell(cell.column.id);
    // }
  };

  const handleEditRow = async () => {
    setIsEditing(selectedFlatRows[0]?.original?.id);
    setEditedRowData(selectedFlatRows[0]?.original);
  };

 


const fetchTalents = async () => {
  try {
      setIsTalentSearchLoading(true);
      const response = await fetch(BASE_URL + `/talent-search/?talent_name=${inputTalentValue}`,{
          headers:{
              'Content-Type': 'application/json',
              "Authorization" : `Bearer ${token}`
          }});
      const data = await response.json();
      if(data.code === 'token_not_valid'){
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token")
          window.location.href = '/login';
      };
      setFilteredTalentData(data?.talent_names);
      setIsTalentSearchLoading(false);
  } catch (error) {
      console.error('Error fetching data:', error);
  }
}


useEffect(() => {
  if(inputTalentValue.trim() !== '') {
      const timeout = setTimeout(() => {
          fetchTalents();
      }, 500);
      return () => {
          clearTimeout(timeout);
      };
  }
}, [inputTalentValue]);


return (
    <Box px={5} py={3}>
      <SkeletonText
        noOfLines={20}
        spacing="2"
        skeletonHeight="6"
        isLoaded={!isLoading}
      >
        {selectedFlatRows.length > 0 ? (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>{selectedFlatRows.length} selected</Box>
            <Flex alignItems={"center"} gap={3}>
              {isEditing && (
                <Button onClick={" "} size={"sm"} colorScheme="blue">
                  Save
                </Button>
              )}
            </Flex>
          </Flex>
        ) : (
   <Box > 
              {/* <BsPersonPlusFill size={20} color="blue" /> */}
              <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search Talent...."
                  width={"350px"}
                  borderRadius={"100vh"}
                  value={filterValue}
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    setPageIndex(1); // Reset pageIndex to 1 on filter change
                    const currentUrl = new URL(window.location.href);
                    currentUrl.searchParams.set('page', 1);
                    window.history.pushState({}, '', currentUrl.toString());
                  }}
                />
                {filterValue && (
                  <InputRightElement cursor={"pointer"}>
                    <CloseIcon
                      w={3}
                      h={3}
                      color="gray.400"
                      onClick={() => setFilterValue("")}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>
          </Flex>
        </Box>
        )}
        <Box
        className="deals-mob"
          fontSize={14}
          mb={1}
          boxShadow="xs"
          h={"calc(94vh - 180px)"}
          // w={"calc(100vw - 290px)"}
          rounded={"md"}
          sx={{
            position: "relative",
          }}
        >
            {/* {    console.log( getTableBodyProps,"propsssssssss")} */}
          <TableContainer
            //   onClick={() => handleCellBlur()} // Handle clicks outside the table
            {...getTableProps()}
           
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": { height: "10px", width: "0px" },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#3182ce",
                borderRadius: "10px",
              },
            }}
          >
            <Table variant="simple" width={"100%"}>
              <Thead bg={"green.400"}   className='fix-header'>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                        <Flex alignItems={"center"}>
                          {column.render("Header")}
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <HiArrowDown />
                              ) : (
                                <HiArrowUp />
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const isRowEditing = isEditing === row?.original?.id;

                  return (
                    <Tr
                      {...row.getRowProps()}
                      _hover={{
                        bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                        boxShadow:
                          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      }}
                    >
                    
                      {row.cells.map((cell) => {
                          // console.log("cell>>>>",cell)
                        const isCellEditing =
                          isRowEditing && editingCell === cell.column.id;
                        return (
                          
                          <Td
                            {...cell.getCellProps()}
                            onTouchEnd={(e) => {
                              // Check if the touch event is a double-tap
                              if (e.timeStamp - lastTapTime < 600) {
                                handleCellClick(row, cell);
                              }
                              setLastTapTime(e.timeStamp);
                            }}
                            onDoubleClick={( ) => handleCellClick(row, cell)} // Handle cell click
                          >
                            {cell.column.id === "selection" ? (
                              <Checkbox
                                {...row.getToggleRowSelectedProps()}
                                disabled= {isRowEditing}
                              />
                            ) : cell.column.Header === "Created At" ? (
                              format(
                                new Date(row.original.created_ts),
                                "MM/dd/yyyy HH:mm:ss"
                              )
                            ) : // cell.column.id === 'partners_domain_name' ? (
                            //     cell.render('Cell')
                            // ) :
                            // cell.column.id === 'whitelisted_company_ids' ? (
                            //     cell.render('Cell')
                            // ) :
                            cell.column.id === "blacklisted_company_ids" ? (
                              cell.render("Cell")
                            ) : isCellEditing ? (
                              <Textarea
                                style={{ resize: "both", minWidth: "250px" }}
                                // w="250px"
                                type="text"
                                value={row.values[cell.column.id]}
                                onChange={(e) => {
                                  const fieldName = cell.column.id;
                                  const value = e.target.value;
                                  if (
                                    fieldName === "meta_tags" ||
                                    fieldName ===
                                      "whitelisted_company_domains" ||
                                    fieldName === "partners_domain_name"
                                  ) {
                                    const metaTagsArray = value
                                      .split(",")
                                      .map((tag) => tag);
                                    row.values[fieldName] = metaTagsArray;
                                    setEditedRowData({
                                      ...editedRowData,
                                      [fieldName]: metaTagsArray,
                                    });
                                  } else {
                                    row.values[fieldName] = value;
                                    setEditedRowData({
                                      ...editedRowData,
                                      [fieldName]: value,
                                    });
                                  }
                                }}
                                onBlur={() => handleCellBlur()} // Auto-save on input blur
                              />
                            ) : (
                              cell.render("Cell")
                            )}
                          </Td>
                        );
                      })}
                    
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Flex>
            Page&nbsp;
            <Text fontWeight={500}>
              {pageIndex } of {pageCount}
            </Text>
          </Flex>
          <Flex alignItems={"center"} gap={3}>
                        <Box _hover={{ bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer", borderRadius: "100vh" }} p={"7px"}>
                            <GoChevronLeft size={24} onClick={() => previousPage()} disabled={pageIndex === 1} />
                        </Box>
                        <Box _hover={{ bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer", borderRadius: "100vh" }} p={"7px"}>
                            <GoChevronRight size={24} onClick={() => nextPage()} disabled={pageIndex === pageCount - 1} />
                        </Box>
                    </Flex>
        </Flex>
      </SkeletonText>
     
    </Box>
  );
};

export default DealsTable;