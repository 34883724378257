
import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import NavigationPanel from '../components/Panel/NavigationPanel'
import TopPanel from '../components/Panel/TopPanel'
import { Outlet } from 'react-router-dom'
const CreatorManager = ({mainPath}) => {
    console.log(' Main path from crator manager');
    
    return (
        <Flex>
            <NavigationPanel mainPath={mainPath} />
            <Box flex='1'>
                <TopPanel />
                <Outlet />

            </Box>
        </Flex>
    )
}

export default CreatorManager
