import React from 'react'
import { SCOUTED_COLUMN } from './Columns'
import { useTable,usePagination,useRowSelect } from 'react-table'
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
  Select,
  FormControl,
  FormLabel,
} from "@chakra-ui/react"; 
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import { AddIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import Checkbox from "./Checkbox";
import { IoMdTrash } from "react-icons/io";
import { format, parse, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import { useMemo,useState } from 'react';
// import { BiData } from 'react-icons/bi';
import { data } from './Columns';
import { TiUserAdd } from "react-icons/ti";
// import { useColorMode } from '@chakra-ui/react';

const Scouting = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const columns = useMemo(() => SCOUTED_COLUMN, []);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const colorMode = useColorMode();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    selectedFlatRows,
  } = useTable( 
    {
      columns,
      data: data || [],
      initialState: { pageIndex: 0, pageSize: 5 },
      manualPagination: true,
      pageCount,
    },
    // useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  console.log('headerGroups', headerGroups.map((headerGroup) => {
  console.log('page>',page)
    return headerGroup

    // return headerGroup.headers.map((header) => {
      // return header;
    // });
  }));
  const AddCreator =(()=>{
    console.log(
      "hello "
    );
    
  })
  return (
    <Box px={5} py={3}>
      <SkeletonText
       noOfLines={20} 
       spacing="2" 
       skeletonHeight="6"
        isLoaded={isLoading}
        >
        <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
          <Box>
            <InputGroup>
              <InputLeftElement pointerEvents = "none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search..."
                width={"350px"}
                borderRadius={"100vh"}
              />
              <InputRightElement cursor={"pointer"}>
                <CloseIcon w={3} h={3} color="gray.400" />
              </InputRightElement>
            </InputGroup>
          </Box>
          <Button size={"sm"}
           colorScheme="blue"
            variant="solid"
            onClick={AddCreator}
            >
          <TiUserAdd />
          </Button>
        </Flex>

        <Box fontSize={14}
         mb={1} 
         boxShadow="xs" 
         h={"calc(100vh - 180px)"} 
         w={"calc(100vw - 290px)"} 
         rounded={"md"}
         sx={{ position: "relative" }}
          >
          <TableContainer
            {...getTableProps()}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": { height: "10px", width: "0px" },
              "&::-webkit-scrollbar-track": { background: "#f1f1f1", borderRadius: "10px" },
              "&::-webkit-scrollbar-thumb": { background: "#3182ce", borderRadius: "10px" },
            }}
          >
            <Table variant="simple" width={"100%"}>
            <Thead bg={"green.400"}   className='fix-header'>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                        <Flex alignItems={"center"}>
                          {column.render("Header")}
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <HiArrowDown />
                              ) : (
                                <HiArrowUp />
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>

              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  
                  return (
                    <Tr
                      {...row.getRowProps()}
                      _hover={{
                        bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                        boxShadow:
                          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      }}
                    >
                      {row.cells.map((cell) => {
                        console.log('cleeding', cell);
                        
                        // const isCellEditing =
                        //   isRowEditing && editingCell === cell.column.id;
                        return (
                          <Td
                            {...cell.getCellProps()}
                            // onTouchEnd={(e) => {
                            //   // Check if the touch event is a double-tap
                            //   if (e.timeStamp - lastTapTime < 600) {
                            //     handleCellClick(row, cell);
                            //   }
                            //   setLastTapTime(e.timeStamp);
                            // }}
                            // onDoubleClick={() => handleCellClick(row, cell)} // Handle cell click
                          >
                            {
                            // cell.column.id === "selection" ? (
                              // <Checkbox
                                // {...row.getToggleRowSelectedProps()}
                                // disabled={isRowEditing}
                              // />
                            // ) 
                            // : 
                            // cell.column.Header === "Created At" ? (
                            //   format(
                            //     new Date(row.original.created_ts),
                            //     "MM/dd/yyyy HH:mm:ss"
                            //   )
                            // ) :
                             // cell.column.id === 'partners_domain_name' ? (
                            // //     cell.render('Cell')
                            // // ) :
                            // // cell.column.id === 'whitelisted_company_ids' ? (
                            // //     cell.render('Cell')
                            // // ) :
                            // cell.column.id === "blacklisted_company_ids" ? (
                            //   cell.render("Cell")
                            // ) : isCellEditing ? (
                            //   cell.column.id === "manager" ? (
                            //     <Select
                            //       name="account_type"
                            //       className="custom-dropdown"
                            //       style={{ resize: "both", minWidth: "200px" }}
                            //       fontSize="sm"
                            //       onChange={(e) => {
                            //         const fieldName = cell.column.id;
                            //         const value = e.target.value;
                            //         const selectedOption = e.target.options[e.target.selectedIndex];
                            //         const selectedId = selectedOption.id;
                            //           console.log('row>>',row);

                                      
                            //         row.values[fieldName] = value;
                            //         setManager({
                            //           influencer_id: row.original.influencer_id,
                            //           manager_id: selectedId, // Make sure 'key' is defined and correct
                            //         });
                            //         setEditedRowData({
                            //           ...editedRowData,
                            //           [fieldName]: value,
                            //         });
                                   
                            //       }}
                                  
                            //       onBlur={() => handleCellBlur()}
                            //       icon={<MdArrowDropDown />}
                            //       // value={userData.account_type}
                            //       placeholder="Select a Manager"
                            //       _focus={{ zIndex: "0" }}
                            //     >
                            //       {managerOptions.map((option) => (
                            //         <option
                            //           key={option.id}
                            //           id={option.id}
                            //           value={
                            //             option.first_name +
                            //             " " +
                            //             option.last_name
                            //           }
                            //         >
                            //           {option.first_name +
                            //             " " +
                            //             option.last_name}
                            //         </option>
                            //       ))}
                            //     </Select>
                            //   ) : cell.column.id === "signing_date" ? (
                            //     <FormControl>
                            //       <Box p={4}>
                            //       <Input
                            //             type="date"
                            //             label="Select Date"
                            //             name={cell.column.id}
                            //             value={selectedDate}
                            //             onChange={handleDateChange}
                            //             onBlur={() => handleCellBlur()}
                            //             // icon={<MdArrowDropDown />}

                            //             _focus={{ zIndex: "0" }}
                            //           />
                             
                            //       </Box>
                            //     </FormControl>
                            //   ) : (
                            //     <Textarea
                            //       style={{ resize: "both", minWidth: "250px" }}
                            //       // w="250px"
                            //       type="text"
                            //       value={row.values[cell.column.id]}
                            //       onChange={(e) => {
                            //         const fieldName = cell.column.id;
                            //         const value = e.target.value;
                            //         if (
                            //           fieldName === "meta_tags" ||
                            //           fieldName ===
                            //             "whitelisted_company_domains" ||
                            //           fieldName === "partners_domain_name"
                            //         ) {
                            //           const metaTagsArray = value
                            //             .split(",")
                            //             .map((tag) => tag);
                            //           row.values[fieldName] = metaTagsArray;
                            //           setEditedRowData({
                            //             ...editedRowData,
                            //             [fieldName]: metaTagsArray,
                            //           });
                            //         } else {
                            //           row.values[fieldName] = value;
                            //           setEditedRowData({
                            //             ...editedRowData,
                            //             [fieldName]: value,
                            //           });
                            //         }
                            //       }}
                            //       onBlur={() => handleCellBlur()} // Auto-save on input blur
                            //     />
                            //   )
                            // )
                            //  :
                             
                             (
                              cell.render("Cell")
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Flex alignItems={"center"} justifyContent={"space-between"} mt={3}>
          <Flex>Page&nbsp;<Text fontWeight={500}>1 of 10</Text></Flex>
          <Flex alignItems={"center"} gap={3}>
            <Box
              _hover={{
                bg: "#EDF2F7",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronLeft size={24} />
            </Box>
            <Box
              _hover={{
                bg: "#EDF2F7",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronRight size={24} />
            </Box>
          </Flex>
        </Flex>
      </SkeletonText>
    </Box>
  );
}

export default Scouting
