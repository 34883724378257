import React, { useEffect } from 'react'
import { Card, CardBody, CardFooter, Image, Divider, Text, SimpleGrid,Box } from '@chakra-ui/react'
import { IoMail } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import InboxAllEmails from '../Table/InboxAllEmails';


const InboxCardModel = ({allEmailsData,handleGetEmail,setIsOpenInboxModel, isLoading}) => {

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  console.log("handlegetemails in 1nd",handleGetEmail)
    const naviagte =useNavigate()
    const [allMails,setAllMails] =useState()
    const [talentMailStatus, setTalentMailStatus] = useState([]);
    const [isActive, setIsActive] = useState('Unread');
    const [isNonGeneral, setIsNOnGeneral] = useState(false);
    const [allTalentEmailsData, setAllTalentEmailsData] = useState([]);

    const routesConfig = [
        { path: '/admin', role: 'Admin' },
        { path: '/creator', role: 'Creator Manager' },
        { path: '/influencer', role: 'Influencer' },
      ];
    
      // Get the user's role from Redux or localStorage
      const role = useSelector((state) => state.role) || localStorage.getItem('Role');
    
      // Find the path based on the user's role
      const userRoute = routesConfig.find((route) => route.role === role)?.path;
    
      console.log('userroutes',userRoute)
    const hanldeAllEmails=(()=>{
      setIsNOnGeneral(false)
        console.log("hiAllEmails",allEmailsData);
        setAllMails(true)
        //handleGetEmail()
        // naviagte(`${userRoute}/InboxAllEmails`,{ state: allEmailsData })

        
    })

    const getTalentDetails = async()=>{
      try {
        const response = await fetch(`${BASE_URL}/get-talent-mail-status/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
    
        const data = await response.json();
        console.log("talentmaildata..>>", data);
        setTalentMailStatus(data.data)
    
        if (response.status === 401) {
       
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
       
      } catch (error) {
   
        console.error("Error:", error);
      }
    }

    const handleTalentEmails=async(email)=>{

      try {
        const response = await fetch(BASE_URL + "/get-talent-mail/", {
          method: "POST",
          body: JSON.stringify({ email: email }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        
        const data = await response.json();
          console.log("inbox daat>>",data);
          
      setAllMails(true)
      setIsNOnGeneral(true)
      setAllTalentEmailsData(data);
          
        if (response.status === 401) {
  
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        if(response.status === 200){
  
          return 

  
        }
      } catch (error) {

        console.error("Error:", error);
      }

      
  }

    
    useEffect(()=>{

      getTalentDetails()
      
    },[])
  return (
    <Box className='page_box'>
      {
        allMails ?
        
        (
            <InboxAllEmails

            allEmailsData={isNonGeneral?allTalentEmailsData:allEmailsData}
            handleGetEmail={handleGetEmail}
            setAllMails={setAllMails}
            isLoading={isLoading}
            isActive={isActive}
            setIsActive={setIsActive}
            />
        )
        :
        (

       
    <Box maxW="1200px" mx="auto" px={4} mt={10} className='card_model_list'> 

    <SimpleGrid columns={[1, 2, 4]} spacing={6} padding={4}  className='inbox_lists'> {/* Set grid columns */}
        {/* new card ...................................................*/}

        <Card maxW="200px" align="center" className='inbox_card'> {/* Adjusted margin-bottom to separate from other cards */}
        <CardBody className='card_top'>
        <span className='notice'
             style={{
                position: 'absolute',
                right:'28px',
                top: '10px', // Adjust the top position as needed
                // Adjust the left position as needed
                backgroundColor: 'rgb(248, 9, 9)', // Optional: background color for better visibility
                color: 'white', // Text color
                padding: '5px', // Padding around text
                borderRadius: '50%' // Rounded corners for the span
              }}
            >{allEmailsData.unread_Email_Count}</span>
          {/* <Text fontSize="lg" fontWeight="bold">Special Card</Text> */}
          {/* <IoMail fontSize={100} /> */}
          <div className='box_img'>
          <IoMail onClick={hanldeAllEmails} style={{backgroundColor:"black" , fontSize: '130px', width: '100%', height: 'auto',color:'white' }} />
        </div>
        </CardBody>
        <Divider />
        <CardFooter>
          <Text fontWeight="bold">General</Text>
        </CardFooter>
      </Card>




        {/* ....................... */}
        {talentMailStatus.map((obj, index) => (
        <Card key={index} maxW="200px" align="center" className='inbox_card' onClick={()=>handleTalentEmails(obj?.inf_inbox_email)}>
          
          <CardBody className='card_top'>
            <span className='notice'
             style={{
                position: 'absolute',
                right:'20px',
                top: '10px', // Adjust the top position as needed
                // Adjust the left position as needed
                backgroundColor: 'rgb(248, 9, 9)', // Optional: background color for better visibility
                color: 'white', // Text color
                padding: '5px', // Padding around text
                borderRadius: '50%' // Rounded corners for the span
              }}
            >{obj.unread_count}</span>
            <div className='box_img'>
            <Image
              src={obj.profile_picture}
              alt={`Professional Image ${index + 1}`}
              borderRadius="lg"
              maxW="150px"
              width="100%"
            /></div>
          </CardBody>
          <Divider />
          <CardFooter>
            <Text fontWeight="bold">{obj.talent_name}</Text> {/* Styled text */}
          </CardFooter>
        </Card>
      ))}
    </SimpleGrid>
    </Box>
     )}
    </Box>
  );
};

export default InboxCardModel;
