import React, { useEffect, useMemo, useState,useRef } from "react";
import { INFLUENCER_COLUMNS } from "./Columns";
import { useTable, usePagination, useRowSelect } from "react-table";
import { FaUserXmark } from "react-icons/fa6";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
  Select,
  FormControl,
  FormLabel,
} from "@chakra-ui/react"; 
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import { AddIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { PiUserSwitchFill } from "react-icons/pi";
import { BsPersonPlusFill } from "react-icons/bs";
import InfluencerFormModal from "../Modal/InfluencerFormModal";
import Checkbox from "./Checkbox";
import { IoMdTrash } from "react-icons/io";
import InfluencerDeleteDialogBox from "../Modal/InfluencerDeleteDialogBox";
import { format, parse, isValid } from "date-fns";
import { defer } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import ChakraDatePicker from "../../utils/ChakraDatePicker";
import { useSelector } from "react-redux";



const InfluencerTable = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const role = useSelector((state) => state.role) || localStorage.getItem('Role');

  const toast = useToast();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const previousRowDataRef = useRef({});
  const columns = useMemo(() => INFLUENCER_COLUMNS, []);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [influencerData, setInfluencerData] = useState([]);
  const [isOpenInfluencerFormModal, setIsOpenInfluencerFormModal] =
    useState(false);
  const [isOpenInfluencerDeleteDialogBox, setIsOpenInfluencerDeleteDialogBox] =
    useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [editedRowData, setEditedRowData] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [lastTapTime, setLastTapTime] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [managerOptions, setManagerOptions] = useState([]);
  const [companyDomains, setCompanyDomains] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
const [manager, setManager] = useState({});
const [managerFlag,setManagerFlag] =useState(false)

  console.log('manager',manager);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    selectedFlatRows,
  } = useTable( 
    {
      columns,
      data: influencerData,
      initialState: { pageIndex: 0, pageSize: 5 },
      manualPagination: true,
      pageCount,
    },
    // useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const handleDateChange = (event) => {
    // console.log(date,"influencerdate");
    const { value, name } = event.target;
    console.log("date>>>",event.target.value)
    // const formattedDate = format(event.target.value, "yyyy-MM-dd");
    setSelectedDate(value);
    setEditedRowData({ ...editedRowData, [name]: value || null});
  };
 
  const fetchInfluencerData = async () => {
    try {
      const response = await fetch(
        BASE_URL +
          (filterValue
            ? `/influencers/?search=${filterValue}&page=${
                pageIndex + 1
              }&page_size=${pageSize}`
            : `/influencers/?page=${
                pageIndex + 1
              }&page_size=${pageSize}&ordering=-created_ts`),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
     console.log("data---->",data)
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }

      setInfluencerData(data?.influencers_data);
      setPageCount(data?.paginator?.total_pages);
      setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);

      toast({
        title: "Fetch Error.",
        description: "Failed to fetch influencer details",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }
  };

  const fetchCompanyDomains = async (id) => {
    try {
      const response = await fetch(BASE_URL + `/whitelist-show/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = '/login';
      }
      return data;
    } catch (error) {
      console.error("Error fetching company domains:", error);
    }
  };

  const fetchManagerOptions = async (id) => {
    try {
      const response = await fetch(BASE_URL + `/get-influencer-users/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (data) {
        setManagerOptions(data.data);
      }
    } catch (error) {
      console.error("Error fetching Manager Options:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      await fetchInfluencerData();
      await fetchManagerOptions();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [pageIndex, pageSize, filterValue]);

  const handleDeleteInfluencer = async (selectedFlatRows) => {
    try {
      setIsLoading(true);
      const deletePromises = selectedFlatRows.map(async (row) => {
        const influencerId = row?.original?.influencer_id;

        const response = await fetch(BASE_URL + "/delete-influencer/", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ influencer_id: influencerId }),
        });
        const data = await response.json();
        console.log('data', data.message);
        

        if (response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = '/login';
        }
        if (response.ok) {
          // Do your success handling here
          return { success: true, influencerId ,message: data.message};
        } else {
          // Do your error handling here
          return { success: false, influencerId ,message: data.message };
        }
      });

      const results = await Promise.all(deletePromises);
  console.log("results: ", results);
  
      results.forEach((result) => {
        const { success, influencerId,message
        } = result;

        if (success) {
          // Handle success
          setInfluencerData((prevData) =>
            prevData.filter(
              (influencer) => influencer.influencer_id !== influencerId
            )
          );
          fetchInfluencerData();
          toast({
            title: "Influencer Deletion Status",
            description: `${message}`,
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
          setIsOpenInfluencerDeleteDialogBox(false);
        } else {
          // Handle error
          toast({
            title: "Deletion Failed",
            description: "Failed to delete influencer details.",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete influencer details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  
  useEffect(() => {
    if (isEditing) {
      const id = selectedFlatRows[0]?.original?.influencer_id;
      if (id) {
        fetchCompanyDomains(id)
          .then((data) => {})
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [isEditing]);

  useEffect(() => {
    // handleEditRow()
    setIsEditing(null);
    setEditedRowData(null);
  }, []);

  //   .................................

  // .....................

  const handleCellBlur = () => {
    // Auto-save when clicking outside the cell
    if (isEditing) {
      setIsEditing(null);
      setEditingCell(null);
      handleSaveRow();

      if(managerFlag){
        handleSaveManager();
      }
      
     
    }
  };



  function parseFormattedDate(dateString) {
    // Parse the date string back to a Date object
    const parsedDate = parse(dateString, "yyyy-MM-dd", new Date());
    // Validate the parsed date
    return isValid(parsedDate) ? parsedDate : null;
  }

  function isValidISODate(dateString) {
    if (!dateString) {
      return false; // Handle null or undefined input
    }
    const date = new Date(dateString);
    return !isNaN(date);
  }

  const handleCellClick = (row, cell) => {
    if (cell.column) {
      setIsEditing(row.original.influencer_id);

      let isDate = isValidISODate(row.original.signing_date);

      if (isDate) {
        const parsedDate = parseFormattedDate(row.original.signing_date);
        let formattedDate = format(parsedDate, 'yyyy-MM-dd');
        setSelectedDate(formattedDate);
      } else {
        setSelectedDate(null);
      }

      setEditedRowData(row.original);
      setEditingCell(cell.column.id);
      previousRowDataRef.current = editedRowData;
    }
  };

  const handleEditRow = async () => {
    setIsEditing(selectedFlatRows[0]?.original?.influencer_id);
    setEditedRowData(selectedFlatRows[0]?.original);
  };

  const handleSaveRow = async () => {
    const influencerId = editedRowData?.influencer_id;

    try {
      const response = await fetch(BASE_URL + "/update-influencer/", {
        method: "PUT",
        body: JSON.stringify(editedRowData, { influencer_id: influencerId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = '/login';
      }
      if (response.ok) {
        setIsEditing(null);
        setEditedRowData(null);
        fetchInfluencerData();
        toast({
          title: "Influencer Updated",
          description: "Influencer updated successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Update Failed",
          description: "Failed to update influencer details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to update influencer details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };


  const handleSaveManager = async () => {
    // const influencerId = editedRowData?.influencer_id;

    setManagerFlag(false)
    try {
      const response = await fetch(BASE_URL + "/update-influencer-manager/", {
        method: "PUT",
        body: JSON.stringify(manager),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = '/login';
      }
      if (response.ok) {
        setIsEditing(null);
        setEditedRowData(null);
        fetchInfluencerData();
        setManagerFlag(false)
        // toast({
        //   title: "manager Updated",
        //   description: "manager updated successfully.",
        //   status: "success",
        //   position: "bottom",
        //   duration: 3000,
        //   isClosable: true,
        // });
      } else {

        
        toast({
          title: "Update Failed",
          description: "Failed to update manager details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to update manager details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  const handleCampaignRecreation = async () => {
    const selectedEmail = selectedFlatRows[0]?.original?.email;

    try {
      const response = await fetch(BASE_URL + "/campaign-recreate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ influencer_email: selectedEmail }),
      });
 
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = '/login';
      }
      if (data.message) {
        toast({
          title: "Campaign Recreation",
          description: `${data.message}`,
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Recreation Failed",
          description: "Failed to campaign recreation.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Recreation Failed",
        description: "Failed to campaign recreation.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  const handleRemoveManager = async (row) => {
    if (!row || row.length === 0) {
      console.error("No row data provided");
      return;
    }
  
    const influencerId = row[0]?.original?.influencer_id;
  
    if (!influencerId) {
      console.error("Influencer ID not found in row data");
      return;
    }
  
    console.log('Removing manager with influencer_id:', influencerId);
  
    try {
      console.log('api hit start');
      
      const response = await fetch(`${BASE_URL}/remove-manager/`, {
        method: "PUT",
        body: JSON.stringify({ influencer_id: influencerId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
       console.log("hello");
       
     console.log('Response',response);
     const data = await response.json();
     
     console.log('data>>>>',data);
     
     
     console.log('resp.message',response.message);
     
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = '/login';
      } else if (response.ok) {
        await fetchInfluencerData(); 
        // Ensure this is awaited if it returns a promise
        toast({
          title: "Manager Removed Status",
          description: `${data.message}`,
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const errorText = await response.text();
        toast({
          title: "Remove Failed",
          description: `Failed to Remove manager: ${errorText}`,
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to Delete manager.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };
  

  return (
    <Box px={5} py={3}>
      <SkeletonText
        noOfLines={20}
        spacing="2"
        skeletonHeight="6"
        isLoaded={!isLoading}
      >
           <Box
  className="removed-manager"
  sx={{
    position: 'absolute',
    right: '150px'
  }}
>
          {
            selectedFlatRows.length === 1 &&  role ==='Admin' &&
            (
              <Button
              onClick={() => handleRemoveManager(selectedFlatRows)}
              size={"sm"}
              style={{marginRight:"-16px"}}
              colorScheme="red"
              variant="solid" >
                <FaUserXmark />
              </Button>
            )
          }
          </Box>
          
        {selectedFlatRows.length > 0 ? (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>{selectedFlatRows.length} selected</Box>
            <Flex alignItems={"center"} gap={3}>
              {/* <Image src='/exchange.png' alt='Exchange' boxSize='24px' cursor={"pointer"} onClick={handleCampaignRecreation} /> */}
              <Button
                onClick={handleCampaignRecreation}
                size={"sm"}
                colorScheme="blue"
                variant="solid"
              >
                <PiUserSwitchFill size={20} color="white" />
              </Button>

              
              {/* {isEditing !== null ? (
                                    <Button onClick={handleSaveRow} size={"sm"} colorScheme='blue'>Save</Button>
                                ) : (
                                    <Button onClick={handleEditRow} size={"sm"} colorScheme='yellow'>Edit</Button>
                                )} */}
              {isEditing && (
                <Button onClick={handleSaveRow} size={"sm"} colorScheme="blue">
                  Save
                </Button>
              )}
              {!isEditing && (
                // <Box onClick={() => setIsOpenInfluencerDeleteDialogBox(true)} bg={"none"} _hover={{ borderRadius: "100vh", bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer" }} p={"8px"}><IoMdTrash size={20} color='red' /></Box>
                <Button
                  onClick={() => setIsOpenInfluencerDeleteDialogBox(true)}
                  size={"sm"}
                  colorScheme="red"
                  variant="solid"
                >
                  <IoMdTrash size={20} color="white" />
                </Button>
              )}
            </Flex>
          </Flex>
         
        ) : (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search influencer..."
                  width={"350px"}
                  borderRadius={"100vh"}
                  value={filterValue}
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    gotoPage(0);
                  }}
                />
                {filterValue && (
                  <InputRightElement cursor={"pointer"}>
                    <CloseIcon
                      w={3}
                      h={3}
                      color="gray.400"
                      onClick={() => setFilterValue("")}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>
            <Button
              onClick={() => setIsOpenInfluencerFormModal(true)}
              size={"sm"}
              colorScheme="blue"
              variant="solid"
            >
              <BsPersonPlusFill size={20} color="white" />
            </Button>
          </Flex>
        )}
        <Box
          fontSize={14}
          mb={1}
          boxShadow="xs"
          h={"calc(100vh - 180px)"}
          w={"calc(100vw - 290px)"}
          rounded={"md"}
          sx={{
            position: "relative",
          }}
        >
          <TableContainer
            //   onClick={() => handleCellBlur()} // Handle clicks outside the table
            {...getTableProps()}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": { height: "10px", width: "0px" },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#3182ce",
                borderRadius: "10px",
              },
            }}
          >
            <Table variant="simple" width={"100%"}>
              <Thead bg={"green.400"}   className='fix-header'>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                        <Flex alignItems={"center"}>
                          {column.render("Header")}
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <HiArrowDown />
                              ) : (
                                <HiArrowUp />
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const isRowEditing =
                    isEditing === row?.original?.influencer_id;

                  return (
                    <Tr
                      {...row.getRowProps()}
                      _hover={{
                        bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                        boxShadow:
                          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      }}
                    >
                      {row.cells.map((cell) => {
                        const isCellEditing =
                          isRowEditing && editingCell === cell.column.id;
                        return (
                          <Td
                            {...cell.getCellProps()}
                            onTouchEnd={(e) => {
                              // Check if the touch event is a double-tap
                              if (e.timeStamp - lastTapTime < 600) {
                                handleCellClick(row, cell);
                              }
                              setLastTapTime(e.timeStamp);
                            }}
                            onDoubleClick={() => handleCellClick(row, cell)} // Handle cell click
                          >
                            {cell.column.id === "selection" ? (
                              <Checkbox
                                {...row.getToggleRowSelectedProps()}
                                disabled={isRowEditing}
                              />
                            ) : cell.column.Header === "Created At" ? (
                              format(
                                new Date(row.original.created_ts),
                                "MM/dd/yyyy HH:mm:ss"
                              )
                            ) : // cell.column.id === 'partners_domain_name' ? (
                            //     cell.render('Cell')
                            // ) :
                            // cell.column.id === 'whitelisted_company_ids' ? (
                            //     cell.render('Cell')
                            // ) :
                            cell.column.id === "blacklisted_company_ids" ? (
                              cell.render("Cell")
                            ) : isCellEditing ? (
                              cell.column.id === "manager" ? (
                                <Select
                                  name="account_type"
                                  className="custom-dropdown"
                                  style={{ resize: "both", minWidth: "200px" }}
                                  fontSize="sm"
                                  onChange={(e) => {
                                    const fieldName = cell.column.id;
                                    const value = e.target.value;
                                    const selectedOption = e.target.options[e.target.selectedIndex];
                                    const selectedId = selectedOption.id;
                                      console.log('row>>',row);

                                      
                                    row.values[fieldName] = value;
                                    setManager({
                                      influencer_id: row.original.influencer_id,
                                      manager_id: selectedId, // Make sure 'key' is defined and correct
                                    });
                                    setManagerFlag(true)
                                    setEditedRowData({
                                      ...editedRowData,
                                      [fieldName]: value,
                                    });
                                   
                                  }}
                                  
                                  onBlur={() => handleCellBlur()}
                                  icon={<MdArrowDropDown />}
                                  // value={userData.account_type}
                                  placeholder="Select a Manager"
                                  _focus={{ zIndex: "0" }}
                                >
                                  {managerOptions.map((option) => (
                                    <option
                                      key={option.id}
                                      id={option.id}
                                      value={
                                        option.first_name +
                                        " " +
                                        option.last_name
                                      }
                                    >
                                      {option.first_name +
                                        " " +
                                        option.last_name}
                                    </option>
                                  ))}
                                </Select>
                              ) : cell.column.id === "signing_date" ? (
                                <FormControl>
                                  <Box p={4}>
                                  <Input
                                        type="date"
                                        label="Select Date"
                                        name={cell.column.id}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        onBlur={() => handleCellBlur()}
                                        // icon={<MdArrowDropDown />}

                                        _focus={{ zIndex: "0" }}
                                      />
                                    {/* <ChakraDatePicker
                                      label="Select Date"
                                      selectedDate={selectedDate}
                                      onChange={handleDateChange}
                                      onBlur={() => handleCellBlur()}
                                      name={cell.column.id}
                                      row={row}
                                    /> */}
                                  </Box>
                                </FormControl>
                              ) : (
                                <Textarea
                                  style={{ resize: "both", minWidth: "250px" }}
                                  // w="250px"
                                  type="text"
                                  value={row.values[cell.column.id]}
                                  onChange={(e) => {
                                    const fieldName = cell.column.id;
                                    const value = e.target.value;
                                    if (
                                      fieldName === "meta_tags" ||
                                      fieldName ===
                                        "whitelisted_company_domains" ||
                                      fieldName === "partners_domain_name"
                                    ) {
                                      const metaTagsArray = value
                                        .split(",")
                                        .map((tag) => tag);
                                      row.values[fieldName] = metaTagsArray;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: metaTagsArray,
                                      });
                                    } else {
                                      row.values[fieldName] = value;
                                      setEditedRowData({
                                        ...editedRowData,
                                        [fieldName]: value,
                                      });
                                    }
                                  }}
                                  onBlur={() => handleCellBlur()} // Auto-save on input blur
                                />
                              )
                            ) : (
                              cell.render("Cell")
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Flex>
            Page&nbsp;
            <Text fontWeight={500}>
              {pageIndex + 1} of {pageCount}
            </Text>
          </Flex>
          <Flex alignItems={"center"} gap={3}>
            <Box
              _hover={{
                bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronLeft
                size={24}
                onClick={() => previousPage()}
                disabled={pageIndex === 0}
              />
            </Box>
            <Box
              _hover={{
                bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronRight
                size={24}
                onClick={() => nextPage()}
                disabled={pageIndex === pageCount - 1}
              />
            </Box>
          </Flex>
        </Flex>
      </SkeletonText>

      <InfluencerFormModal
        isOpen={isOpenInfluencerFormModal}
        onClose={() => {
          setIsOpenInfluencerFormModal(false);
        }}
      />

      <InfluencerDeleteDialogBox
        isOpen={isOpenInfluencerDeleteDialogBox}
        onClose={() => {
          setIsOpenInfluencerDeleteDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleDeleteInfluencer={handleDeleteInfluencer}
      />
    </Box>
  );
};

export default InfluencerTable;
