import { createStore } from 'redux';

// // Initial state
// const initialState = {
//   role: null,
// };
// Initial state
const initialState = {
  role: null,
  userImg: null,
  userEmail: null,
  token: null,
  myFunction: null, // Add this line to store the function
};

// Action types
const SET_ROLE = 'SET_ROLE';
const SET_USER_IMG = 'SET_USER_IMG';
const SET_USER_EMAIL = 'SET_USER_EMAIL';
const SET_TOKEN = 'SET_TOKEN';
const SET_FUNCTION = 'SET_FUNCTION'; // Add this line

// Action creators
export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});

export const setRole = (role) => ({
  type: SET_ROLE,
  payload: role,
});

export const setUserImg = (userImg) => ({
  type: SET_USER_IMG,
  payload: userImg,
});

export const setUserEmail = (email) => ({
  type: SET_USER_EMAIL,
  payload: email,
});

export const setFunction = (func) => ({
  type: SET_FUNCTION,
  payload: func,
});

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case SET_USER_IMG:
      return {
        ...state,
        userImg: action.payload,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_FUNCTION:
      return {
        ...state,
        myFunction: action.payload, // Store the function
      };
    default:
      return state;
  }
};

// Create store
const store = createStore(reducer);

export default store;

