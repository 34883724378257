import React from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,Button } from '@chakra-ui/react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Thead, Tbody, Tr, Th, Td, Select } from '@chakra-ui/react';
import {
    Box,
    Flex,
    useColorMode,
    useToast,

  } from "@chakra-ui/react";
  import axios from 'axios';
  import { useState } from 'react';
  import DealsFollowUpModel from './DealsFollowUpModel';


const DealsFollowConfirmModel = ({isOpen,onClose,talent_name,rowData,onhandleState,fetchinfluencerDealsData,FollowupEmails}) => {
  const [isOpenDealsFollowUpModal,setIsOpenDealsFollowUpModel] = useState(false)
  const[FollowUpApi,setFollowUpApi]=useState(false)
   
  const handleFollowUpModel =(onClose)=>{
    setFollowUpApi(true)
                
    setIsOpenDealsFollowUpModel(true)
    console.log("hey v");
    onClose();


    }
  return (
    <>
    <AlertDialog isOpen={isOpen} onClose={onClose}>
    <AlertDialogOverlay>
    <AlertDialogContent>
        <AlertDialogHeader fontSize='lg' fontWeight='bold'>Confirm Follow Up</AlertDialogHeader>
        <AlertDialogBody>Would you like to schedule a follow-up?</AlertDialogBody>
        <AlertDialogFooter>
            <Button size={"sm"} onClick={onClose}>No</Button>
            <Button size={"sm"} colorScheme='red'   spinnerPlacement='end' ml={3} 
            onClick ={()=>{handleFollowUpModel(onClose)}}  >Yes</Button>
        </AlertDialogFooter>
    </AlertDialogContent>
    </AlertDialogOverlay>
</AlertDialog>


<DealsFollowUpModel
           isOpen={isOpenDealsFollowUpModal}
          onClose = {()=>setIsOpenDealsFollowUpModel(false)}
          talent_name= {talent_name}
          rowData={rowData}
          onhandleState={onhandleState}
          FollowUpApi  = {FollowUpApi}
          fetchinfluencerDealsData={fetchinfluencerDealsData}
          FollowupEmails={FollowupEmails}
          //  data ={selectedFlatRows}
         />


</>

)
}

export default DealsFollowConfirmModel