import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody, CardFooter, Text, Box, Button, Input, Flex,useToast, chakra } from '@chakra-ui/react'
import { Grid, GridItem } from '@chakra-ui/react'
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { FaSackDollar } from "react-icons/fa6";
import { FaHandshakeAngle } from "react-icons/fa6";
import "./TestBord.css"
import axios from 'axios'
import { FaBullhorn } from "react-icons/fa";
import { useSelector } from 'react-redux';
import {InfoIcon} from '@chakra-ui/icons'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'

const TestBord = () => {
  const [showHeader, setShowHeader] = useState()
  const [deals,setDeals]=useState("")
  const [OutrichData,setOutrichData]=useState("")
  const[annualRevanue,setAnnualRevanue] = useState('')
  console.log('annualRevenye>>',annualRevanue);
  
  const role = useSelector((state) => state.role) || localStorage.getItem('Role');
  const initialFocusRef = React.useRef()
const[roles,setRoles]=useState(role)
  // const[currentYear,setCurrentYear] = useState(false)
  // const[lastYear, setLastYear] = useState(false)
  // const[currentMonth,setCurrentMonth] =useState(false)

  const[FilterOut,setFiltreOut] = useState("")
  const [filterDeals,setFilterDeals] = useState('')
  console.log('filterdeals>>',filterDeals)

  const[startDate,setStartDate] =useState("")
  const[endDate,setEndDate] = useState("")
  const[error,setError] =useState(null)
  const [sales,setSales] =useState("")

  const[curSaleYear,setCurSaleYear]=useState(false)
  const[lastSaleyear,setLastSaleyear]=useState(false)
  const[curSaleMonth,setCurSaleMonth] =useState(false)

  const[SalesFilter,setSalesFilter]=useState("")
  const[salesSDate,setSalesSDate]=useState("")
  const[salesEDate,setSalesEDate] = useState("")
  const [error1,setError1]=useState("")
const[startDealsDate,setStartDealsDate]=useState()
const[endDealsDate,setEndDealsDate] = useState()


  const[filterCommission,setFilterCommission]= useState("monthly")
  
  const[CommissionInput,setCommissionInput]=useState("")
  const [comissionData,setComissionData]= useState("")
  const[nextCommission,setNextCommission]=useState(false)
  const[message,setMessage] =useState("")

  console.log("startdate-->",startDate)
  console.log("enddate--->",endDate)
   const toast =useToast();
   const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
   const token = localStorage.getItem("token");
  

   const getRoleInsights = (role) => {
    console.log("role>>>",role);
    switch (role) {
      case 'Admin':
        return ["Commission goal", "Sales", "Deals", "Outreach"];
      case 'Creator Manager':
        return ["Commission goal", "Sales", "Deals", "Outreach"];
      case 'Influencer':
        return ["Annual Revenue", "Deals", "Outreach"];
      default:
        return []; // Optionally handle unknown roles
    }
  };  

 const handleCommissionInput =((e)=>{
  setCommissionInput(e.target.value)
 })

 const handleDeals = async () => {  
  console.log('role>>>: ', roles);
  
  let url;  // Define url here to use it for both roles
  
  if (roles === 'Influencer') {
    url = `${BASE_URL}/influencer-deals-stats/`;
    console.log('filterDeals>>>>api',filterDeals);
    
    if (filterDeals === "CurrentYear") {
      console.log("filterDeals>>>>api2")
      url += '?filter=current_year';
    } else if (filterDeals === "LastYear") {
      url += '?filter=last_year';
    } else if (filterDeals === "CurrentMonth") {
      url += '?filter=month';
    } else if (startDealsDate && endDealsDate) {
      url += `?filter=custom&start_date=${startDealsDate}&end_date=${endDealsDate}`;
    }
  } else if (roles === 'Admin' || roles === 'Creator Manager') {
    url = `${BASE_URL}/count-deals/`;
  } else {
    // Handle the case when role is neither 'Influencer' nor 'Admin'
    toast({
      title: "Role Error",
      description: "Invalid role specified.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return;  // Exit early
  }

     try {
      console.log("hi->","hi")
      const response = await fetch(url, {
        method: 'GET',
        
        headers: {
          'Authorization': `Bearer ${token}`, 
        },
      });
      console.log("response",response)
      const data = await response.json();
      console.log("data--->",data);
      console.log(data.count_deals,"....respstatus");
      if ( data && (role === "Admin" || role === "Creator Manager")) {
        setDeals(data.count_deals)
        console.log('data for admin>>',data);
      }
      else if(data && roles === 'Influencer'){
        setDeals(data)
      }
      else {
        toast({
          title: "No Data",
          description: "There is No Data in Deals section.",
          status: "error",
          duration: 3000, 
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Upload failed",
        description: "There was an error uploading your file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error in Deals section:", error);
    } finally {
 
    }
  };

  const handleLastYear =(()=>{
    // setLastYear(true)
    if(FilterOut === "LastYear"){
      setFiltreOut("")
    }
    else{
      setFiltreOut("LastYear")
      setStartDate("")
      setEndDate("")
    }

  })

  const handleDealsLastYear =(()=>{
    // setLastYear(true)
    if(filterDeals === "LastYear"){
      setFilterDeals("")
    }
    else{
      setFilterDeals("LastYear")
      setStartDealsDate("")
      setEndDealsDate("")
    }
  })
   
   

  const handleCurrentYear =(()=>{
    // setCurrentYear(true)
    if(FilterOut === "CurrentYear"){
      setFiltreOut("")
    }else{
      setFiltreOut("CurrentYear")
      setStartDate("")
      setEndDate("")
    }
  })

  const handleDealsCurrentYear =(()=>{
    // setCurrentYear(true)
    if(filterDeals === "CurrentYear"){
      setFilterDeals("")
    }else{
      setFilterDeals("CurrentYear")
      setStartDealsDate("")
      setEndDealsDate("")
    }
  })



  const handleCurrentMonth =(()=>{
  //  setCurrentMonth(true)
  if(FilterOut === "CurrentMonth"){
   setFiltreOut("")
  }
  else{
    setFiltreOut("CurrentMonth")
    setStartDate("")
    setEndDate("")
  }
  })

  const handleDealsCurrentMonth =(()=>{
    //  setCurrentMonth(true)
    if(filterDeals === "CurrentMonth"){
      setFilterDeals("")
    }
    else{
      setFilterDeals("CurrentMonth")
      setStartDealsDate("")
      setEndDealsDate("")
    }
  
    })

  const handleStartDate=((e)=>{
    setFiltreOut("")
    setStartDate(e.target.value)
   

  })
  
  const handleEndDate=((e)=>{
    
    if(startDate && e.target.value && e.target.value <= startDate)
    {
      setError('End date cannot be before the start date.')
    }
    else{
      setFiltreOut("")
      setEndDate(e.target.value)

      if(error) {
        setError(null)
      }
    }
    console.log("adte---.>",e.target.value)
    
   
  })

  const handleStartDealsDate=((e)=>{
    setFilterDeals("")
    setStartDealsDate(e.target.value)
   

  })
  const handleEndDealsDate=((e)=>{
    
    if(startDealsDate && e.target.value && e.target.value <= startDealsDate)
    {
      setError('End date cannot be before the start date.')
    }
    else{
      setFilterDeals("")
      setEndDealsDate(e.target.value)

      if(error) {
        setError(null)
      }
    }
    console.log("adte---.>",e.target.value)
    
   
  })


  const getOutrichs = async () => { 
    let url = `${BASE_URL}/outreach-status/`

     if(FilterOut === "CurrentYear"){
      url+='?filter=current_year'
     }else if(FilterOut === "LastYear"){
       url+='?filter=last_year'
     }else if(FilterOut === "CurrentMonth"){
      url+='?filter=month'
     }else if (startDate && endDate) {
     
      url+=`?filter=custom&start_date=${startDate}&end_date=${endDate}`
     }
     else {
      url=url
     }

    try {
      const result = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }); 
    
      if(result.status === 200) {
        console.log("result--->", result.data);
        setOutrichData(result.data)

      }
     

    } catch (error) {
      console.log("error>>>", error);
      toast({ 
        title: "Failed",
        description: "Error.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  }


  const getSales=async()=>{
        let url =`${BASE_URL}/deals-status/`
        if(SalesFilter === "CurrentYear") {
          url+='?filter=current_year'
        }
        else if (SalesFilter ===  "LastYear"){
          url+='?filter=last_year'
        }
        else if(SalesFilter === "CurrentMonth"){
          url+='?filter=month'
        }
        else if(salesSDate && salesEDate){
          url+=`?filter=custom&start_date=${salesSDate}&end_date=${salesEDate}`
        }
        else {
          url=url
        }
    try{
          const result = await axios.get(url,{
            headers :{
              Authorization :`Bearer ${token}`
            }
          })
          console.log("result Saless --->",result.data);
          if(result.status===200){
            setSales(result.data)

          }
    }
    catch (error){
        console.log("error--->",error);
    }

}



     const handleCurrentSalesYear =(()=>{
      if(SalesFilter === "CurrentYear"){
        setSalesFilter("")
      }
      else{
        setSalesFilter("CurrentYear")
        setSalesEDate("")
        setSalesSDate("")
      }
     })

     const handleLastSalesYear =(()=>{
       if(SalesFilter === "LastYear") {
            setSalesFilter("")
       }else {
        setSalesFilter("LastYear")
        setSalesEDate("")
        setSalesSDate("")
       }
     })

     const handleCurrentSalesMonth =(()=>{
      if(SalesFilter === "CurrentMonth"){
        setSalesFilter("")
      }else{
        setSalesFilter("CurrentMonth")
        setSalesEDate("")
        setSalesSDate("")
      }
     })

     const handleSalesStart =((e)=>{
      setSalesFilter("")
          setSalesSDate(e.target.value)
          
     })

    const handleSalesEnd =((e)=>{
      if(salesSDate && e.target.value && e.target.value <= salesSDate){
         setError1('End date cannot be before the start date.')
      }
      setSalesFilter("")
          setSalesEDate(e.target.value)
        
    })

     useEffect(()=>{
      getOutrichs()
},[FilterOut,startDate,endDate])


useEffect(()=>{
      getSales()
},[SalesFilter,salesSDate,salesEDate])
  
useEffect(()=>{
  handleDeals();

},[filterDeals,startDealsDate,endDealsDate])

  useEffect(()=>{
    console.log("hello use effect");
    getOutrichs()
    handleDeals();
    getSales();
 
  },[])

const handleCommisionSubmit =(async ()=>{
  // if (filterCommission === "" || CommissionInput === ""){
  //   setNextCommission(false)
  //   return  toast({ 
  //     title: "Value is not provide ",
  //     description: "Error.",
  //     status: "error",
  //     position: "bottom",
  //     duration: 3000,
  //     isClosable: true,
  //   });

  // }
  const Object ={
    period_type:filterCommission,
    goal_amount:CommissionInput
  }

    try{
   const result= await axios.post(`${BASE_URL}/goals/`,Object,{
    headers :{
      Authorization :`Bearer ${token}`
    }

   })
   if(result.status === 200 ){
    setComissionData(result.data.difference)
    toast({
      title: "Success",
      description: "Commission goal added successfully.",
      status: "success",
      duration: 3000, 
      isClosable: true,
    });
   }
  
    }
    catch (error){
      console.log("error-->",error)

    }

})
  const handleCMonthly =(()=>{
      setFilterCommission("monthly")
  })

  const handleCQuarterly =(()=>{
     setFilterCommission("quarterly")
  })

  const handleCYearly =(()=>{
    setFilterCommission("yearly")
  })
 
 const  handleGetCommission=(async()=>{
  let url =`${BASE_URL}/goals/`
  // if(!nextCommission){
  //   console.log("hello not comm get")
  //   return
  // }
       if(filterCommission === "monthly" ){
          url+='?period_type=monthly'
      }
      else if(filterCommission ==="quarterly" ){
            url+='?period_type=quarterly'
      }
      else if (filterCommission === "yearly" ){
            url+='?period_type=yearly'
      }
      // else{
      //   url=url
      // }

        try{
          const result =await axios.get(url,{
            headers :{
              Authorization :`Bearer ${token}`
            }

          })
          if(result.status === 200 ){
            setComissionData(result.data.difference)
            setMessage(result.data.message)
            setCommissionInput(result?.data?.goal_amount)
           }
        }
        catch(error){
          console.log("error--->",error)
          if(error?.response?.status === 404){
            // console.log('error in fetching -> ', error)
            setComissionData(0)
            setMessage(error?.response?.data?.message)
            setCommissionInput('')
          }
        }
  })
  console.log("commision--->",filterCommission)


  useEffect(()=>{
      handleGetCommission()
  },[filterCommission])

  console.log("commission input",CommissionInput)

  const GetAnnualRevenue=async()=>{
    let url =`${BASE_URL}/annual-revenue/`
    
try{
      const result = await axios.get(url,{
        headers :{
          Authorization :`Bearer ${token}`
        }
      })
      console.log("result Annual --->",result.data);
      if(result.status===200){
        // setSales(result.data)
        setAnnualRevanue(result?.data)

      }
}
catch (error){
    console.log("error--->",error);
}

}

useEffect(()=>{
  GetAnnualRevenue()
},[])

function formatCurrency(value) {

  if (value == null || value === '') {
    return  // or an empty string or some other fallback
  }
  // Ensure the value is a valid number
  const numericValue = Number(value);
  
  // if (isNaN(numericValue)) {
  //   return 'Annual Revenue'; // or return an empty string or some other fallback
  // }

  return numericValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

  return ( 
    <div className='bgouter'>
      <div className='bg-outer'>
        <Card size='md' className='headingss bg_styleone carddesignsec'>
          <CardBody className='boxbg_box'>
            {/* <Text>Welcome {localStorage.getItem('userName') ? localStorage.getItem('userName'): ""}</Text> */}
            <Text>Welcome {localStorage.getItem('userName') ? localStorage.getItem('userName')=="rakshu"?localStorage.getItem('userName')+" Topu":localStorage.getItem('userName'): ""}</Text>
          </CardBody >
        </Card>
        {/* <img src={Dashimage} width={100}/> */}

        {getRoleInsights(role).includes('Commission goal') && <div className='card_designouter board_sec commision_goals'>
          <Flex className='white-sec board_heading'>
            <FaSackDollar size={28} />
            <h1>Commission goal</h1>
          </Flex>
          <Card size='lg' className='padding-card carddesignsec'>
            <CardHeader className='widthfull'>
              <Box className='flex-space' style={{ display: 'flex', alignItems: 'center' }}>
                <Box className='rleft-btn'>
                  <Text style={{ marginRight: '5px' }}>Snapshot:</Text>
                     <Box className='btn-flex'>
                      <Button 
                  style={{ marginRight: '5px' }} 
                  onClick={handleCYearly}
                  className={filterCommission === "yearly" ? "active " :'inactive'}
                  >
                    Yearly
                  </Button>
                  <Button
                   style={{ marginRight: '5px' }}
                    onClick={handleCQuarterly}
                    className={filterCommission === "quarterly" ? "active " :'inactive'}
                    >
                      Quarterly
                      </Button>
                      <Button
                   style={{ marginRight: '5px' }}
                    onClick={handleCMonthly}
                    className={filterCommission === "monthly" ? "active " :'inactive'}
                     >
                      Monthly
                      </Button>
                </Box>
                <Box className='right-btn' >
                  <Input
                  type='text'
                  style={{ marginRight: '5px'}}
                  onChange={(e)=>handleCommissionInput(e)}
                  value={CommissionInput}
                  placeholder='Enter Goal Amount'
                  />
                  
                  <Button 
                    style={{ marginRight: '5px' }} 
                    onClick={async()=>{ 
                      await handleCommisionSubmit();
                      handleGetCommission();
                    }}
                  >
                    set
                  </Button>
              
                </Box>
                </Box>
              </Box>
            </CardHeader>
            <box className='box-grid'>
            <CardBody className='col-result'>
     
              <Grid templateColumns='repeat(1, 1fr)' gap={6}>
            
                < GridItem w='100%'   >
                  <Text>{comissionData &&
                  `${comissionData
                    .toLocaleString('en-US',{
                      style:'currency',
                      currency:'USD',
                      minimumFractionDigits:2,
                      maximumFractionDigits:2
                     })

                  }`}</Text>
               <h6 className= {message === 'Under Goal' ?'red-text'
                    :message ===  "Over Goal" ? "Green-Text" : ""}>{message}</h6>
                </ GridItem>

              </Grid>
           
            </CardBody>
            </box> 
          </Card>
        </div>}

        {getRoleInsights(role).includes('Sales') && <div className='board_sec thiredsection'>
          <Flex className='white-sec board_heading'>
            <FaHandshakeAngle size={28} />
            <h1>Sales</h1>
          </Flex>
          <Card size='lg' className='carddesignsec'>
            <CardHeader className='input_btn_sec'>
              <Box className='filter-main-cl' style={{ display: 'flex', alignItems: 'center', width:'90%' }}>
                <Text style={{ marginRight: '5px' }}>Snapshot:</Text>
                <Box className='current-year-filter'>
                <Button  size='sm'
                 style={{ marginRight: '5px' }}
                 className={SalesFilter === "CurrentYear" ? 'active' : 'inactive'} 
                 onClick={handleCurrentSalesYear}>
                  Current Year
                  </Button>
                <Button size='sm' 
                style={{ marginRight: '5px' }}
                className={SalesFilter === "LastYear" ? 'active' : 'inactive'} 
                 onClick={handleLastSalesYear}>
                  Last Year
                  </Button>
                <Button  size='sm'
                 style={{ marginRight: '5px' }} 
                 className={SalesFilter === "CurrentMonth" ? 'active' : 'inactive'} 
                onClick={handleCurrentSalesMonth}>
                  Current Month
                  </Button>
                  </Box>
                  <Box className='date-filter'>
                <Input
                  style={{ marginRight: '5px', width: '150px' }}
                  type='date'
                  placeholder='Select Start Date'
                  onChange={(e)=>handleSalesStart(e)}
                  value={salesSDate}
                  className={salesSDate !== "" ? "DateActive":"DateInactive"}
                />
                <span className='dash_line'>-</span>
                <Input
                  placeholder='Select Start Date'
                  style={{ width: '150px' }}
                  type='date'
                  onChange={(e)=>handleSalesEnd(e)}
                  value={salesEDate}
                  className={salesEDate !== "" ? "DateActive":"DateInactive"}
                />
                </Box>
              <Box>{error1}</Box>
              </Box>
            
            </CardHeader>
            <CardBody>
              <Grid templateColumns='repeat(5, 1fr)' gap={6}>
                {Object.entries(sales).map(([key,value],index)=> {
                 
                       const FormattedValue =(key === 'total_sales' || key ==='average_sale' || key === 'sales') ?
                       Number(value).toLocaleString('en-US',{
                        style:'currency',
                        currency:'USD',
                        minimumFractionDigits:2,
                        maximumFractionDigits:2
                       }) : value;
                       console.log("key---->",key,value);

                              return  < GridItem w='100%' h='10' bg='blue.500' className='col-result'>
                                <Text>{FormattedValue}</Text>
                                    {key.replace(/_/g, ' ')  
                                        .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize the first letter of each word}
                                    }
                              </ GridItem>
                
                                })}
              </Grid>
            </CardBody>
          </Card>
        </div>}

        {getRoleInsights(role).includes('Annual Revenue') && <div className='board_sec thiredsection'>
          <Flex className='white-sec board_heading'>
          <FaSackDollar size={28} />
            <h1>Annual Revenue</h1>
          </Flex>
          <Card size='lg' className='carddesignsec carddesignsec_newone'>
          <CardHeader className='input_btn_sec'>
              <Box className='filter-main-cl info_flex' style={{ display: 'flex', alignItems: 'center', width:'90%' }}>
              <Popover
      initialFocusRef={initialFocusRef}
      placement='left-start'
      closeOnBlur={false}
    >
            <PopoverTrigger>
            < InfoIcon  style={{ marginLeft: '1033px' }} 
                 />
            </PopoverTrigger>
            <PopoverContent color='white' bg='blue.800' borderColor='blue.800' className='left-popup-icon'>
        {/* <PopoverHeader pt={4} fontWeight='bold' border='0'>
          Manage Your Channels
        </PopoverHeader> */}
        <PopoverArrow bg='blue.800' />
        {/* <PopoverCloseButton className='left-popup-closebutton' /> */}
        <PopoverBody>
         {annualRevanue.info_message}
        </PopoverBody>
        </PopoverContent>



</Popover>

         
                 
                 
                 
                
              </Box>
            
            </CardHeader>
            <CardBody>
              <Grid templateColumns='repeat(5, 1fr)' gap={6}>
                < GridItem w='100%' h='10' bg='blue.500' className='col-result'>
                                <Text>{formatCurrency(annualRevanue?.annual_revenue)}</Text>
                                   <p>{annualRevanue?.split_message}</p>
                              </ GridItem>
                
                             
              </Grid>
            </CardBody>
          </Card>
        </div>}

        {getRoleInsights(role).includes('Deals') && <div className='board_sec fourthsection'>
          <Flex className='white-sec board_heading'>
            <RiMoneyDollarCircleFill size={28} />
            <h1>Deals</h1>
          </Flex>
          <Card size='lg' className='carddesignsec'>
      
          <CardHeader className='input_btn_sec'>
              <Box className='filter-main-cl snapshot_flex' style={{ display: 'flex', alignItems: 'center', width:'90%' }}>
                <Text style={{ marginRight: '5px' }}>Snapshot:</Text>
                {role=="Influencer" && <>
                <Box className='rleft-btn-date'>
                <Button 
                style={{ marginRight: '5px' }} 
                className={filterDeals === "CurrentYear" ? 'active' : 'inactive'} 
                onClick={handleDealsCurrentYear}>
                   Current Year
                   </Button>
                <Button style={{ marginRight: '5px' }}
                 className={filterDeals === "LastYear" ? 'active' : 'inactive'}
                   onClick={handleDealsLastYear}>
                    Last Year
                    </Button>
                <Button style={{ marginRight: '5px', display:'flex'}}  
                 className={filterDeals === "CurrentMonth" ? 'active' : 'inactive'}
                 onClick={handleDealsCurrentMonth}>
                  Current Month
                  </Button>
                  </Box>
                {/* <Button style={{ marginRight: '5px' }}>Custom</Button> */}
                <Box className='date-fliter-r'>
                <Input
                  style={{ marginRight: '5px', width: '150px' }}
                  type='date'
                  placeholder='Select Start Date'
                  onChange={(e)=>handleStartDealsDate(e)}
                  value={startDealsDate}
                  className={startDate !== "" ? "DateActive":"DateInactive"}
                />
               <span className='dash_line'>-</span>
                <Input
                  placeholder='Select Start Date'
                  style={{ width: '150px' }}
                  type='date'
                  onChange={(e)=>handleEndDealsDate(e)}
                  value={endDealsDate}
                  className={endDate !== "" ? "DateActive":"DateInactive"}
                />
                </Box>
                <Box className='error'>{error}</Box>
                </>}

              </Box>
            
            </CardHeader>

            <CardBody className='dealsbody'>
              <Grid templateColumns='repeat(5, 1fr)' gap={6}>
              {deals && Object.entries(deals).map(([key, value], index) => (
               
                < GridItem w='100%' h='10' bg='blue.500' className='col-result'>
                  {roles === 'Influencer' && (key === 'Payout' || key ==='Gross_Revenue') ?
                  (<Text>{formatCurrency(value)}</Text>) :(<Text>{value}</Text>)
                  }
                  {key.replace(/_/g,' ')}
                </ GridItem>
                      ))}
              </Grid>
            </CardBody>
          </Card>
        </div>}

        {getRoleInsights(role).includes('Outreach') && <div className='board_sec sectionfive'>
          <Flex className='white-sec board_heading'>
          <FaBullhorn size={28} />
            <h1>Outreach</h1>
          </Flex>
          <Card size='lg' className='carddesignsec'>
            <CardHeader className='input_btn_sec'>
              <Box className='filter-main-cl' style={{ display: 'flex', alignItems: 'center', width:'90%' }}>
                <Text style={{ marginRight: '5px' }}>Snapshot:</Text>
                <Box className='rleft-btn-date'>
                <Button 
                style={{ marginRight: '5px' }} 
                className={FilterOut === "CurrentYear" ? 'active' : 'inactive'} 
                onClick={handleCurrentYear}>
                   Current Year
                   </Button>
                <Button style={{ marginRight: '5px' }}
                 className={FilterOut === "LastYear" ? 'active' : 'inactive'}
                   onClick={handleLastYear}>
                    Last Year
                    </Button>
                <Button style={{ marginRight: '5px', display:'flex'}}  
                 className={FilterOut === "CurrentMonth" ? 'active' : 'inactive'}
                 onClick={handleCurrentMonth}>
                  Current Month
                  </Button>
                  </Box>
                {/* <Button style={{ marginRight: '5px' }}>Custom</Button> */}
                <Box className='date-fliter-r'>
                <Input
                  style={{ marginRight: '5px', width: '150px' }}
                  type='date'
                  placeholder='Select Start Date'
                  onChange={(e)=>handleStartDate(e)}
                  value={startDate}
                  className={startDate !== "" ? "DateActive":"DateInactive"}
                />
               <span className='dash_line'>-</span>
                <Input
                  placeholder='Select Start Date'
                  style={{ width: '150px' }}
                  type='date'
                  onChange={(e)=>handleEndDate(e)}
                  value={endDate}
                  className={endDate !== "" ? "DateActive":"DateInactive"}
                />
                </Box>
                <Box className='error'>{error}</Box>
              </Box>
            
            </CardHeader>
            <CardBody>
              <Grid templateColumns='repeat(5, 1fr)' gap={6}>
                {Object.entries(OutrichData).map(([key, value],index)=>{
                             return   < GridItem w='100%' h='10' bg='blue.500' className='col-result'>
                                <Text>{value}</Text>
                                {key.replace(/_/g, ' ')  
                                        .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize the first letter of each word}
                                    }

                              </ GridItem>
                }) }
              </Grid>
            </CardBody>
          </Card>
        </div>}
      </div>
    </div>
  )
}

export default TestBord
