import React from 'react'
import {
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
    Divider,
    HStack,
    Skeleton,
    Text,
    Button,
    ButtonGroup,
    Box
  } from '@chakra-ui/react'
  import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
// import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import Checkbox from "./Checkbox";
import "./InboxEmail.css"
import AllMessegesModel from '../Modal/AllMessegesModel';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';


const InboxAllEmails = ({allEmailsData,handleGetEmail,setAllMails,isLoading,isActive,setIsActive}) => {

  console.log("handleGetEmail is in2nd ",handleGetEmail)
  const handlemail = useSelector((state) => state.myFunction) ||localStorage.getItem('handleGetEmail')
    const location = useLocation();
    const receivedDatas = location.state;
    const [receivedData, setReceivedData] = useState(allEmailsData);
    //const [isLoading, setIsLoading] = useState(false)
    const[messeges,setMesseges] =useState(null)
    
    
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

    console.log('rec data>>',receivedData);
    // console.log("inrsult",receivedData.emails)

    const snippets = receivedData?.emails?.map((item) => item.messages[0].subject);

    const extractTime = (dateString) => {
      const timeMatch = dateString.match(/\d{2}:\d{2} [APM]{2}/);
      return timeMatch ? timeMatch[0] : '';
    };

console.log("snippets",snippets);

const hanldeTextSubject  = async (messages) => {
  console.log('messages>>', messages);
  setMesseges(messages);


  if (messages.some(email => email.status === 'unread')) {

  // Filter out the unread message IDs
  const unreadMessageIds = messages
    .filter(message => message.status === 'unread')
    .map(message => message.id);

  console.log("unread message ids>>", unreadMessageIds);

  // Hit the fetch POST API
  try {
    const payload = {
      ids: unreadMessageIds
    };

    const response = await fetch(BASE_URL + "/make-read/", {
      method: "POST",
      body: JSON.stringify(payload), // Use JSON.stringify to send payload
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ensure token is defined
      },
    });

    const data = await response.json();
    console.log("inbox data>>", data);

    if (response.status === 401) {
    
      console.error("Unauthorized: User session expired");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("token");
      window.location.href = "/login";
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (response.status === 200) {
      handleGetEmail()
      // Handle success logic here
      // Example: toast success, close models, etc.
    }

  } catch (error) {
    // Handle the error
  
    console.error("Error:", error);
  }
}
};

function removeContentBetweenBrackets(str) {
  // Replace content inside < > along with the brackets
  return str.replace(/<[^>]*>/g, '').trim();
}

function removeHtmlTags(str) {
  // Replace all tags with a space
  return str.replace(/<[^>]*>/g, ' ').replace(/\s+/g, ' ').trim();
}

function getStringWidth(text) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = window.getComputedStyle(document.body).font; // Use the font style of the body or a specific element if needed
  return context.measureText(text).width;
}

function sliceStringToFitScreen(text) {
  //console.log("text>>>>>>",text)
  const screenWidth = window.innerWidth;
  console.log("screenwidth>>",screenWidth);
  const maxWidth = screenWidth * 0.20; // 50% of the screen width
  const step = 10; // Adjust this step size if needed
  let slicedText = '';
  let currentIndex = 0;

  while (currentIndex < text.length) {
    const chunk = text.slice(currentIndex, currentIndex + step);
    console.log("chunk>>>",chunk);
    const newText = slicedText + chunk;
    if (getStringWidth(newText) > maxWidth) {
      return slicedText.trimEnd() + '...'; // Add ellipsis if the text overflows
    }
    slicedText = newText;
    currentIndex += step;
  }
  //console.log("slicedString>>>>>>>>>>", slicedText);
  return slicedText;
}

function processString(str) {
  const cleanedString = removeHtmlTags(str);
  return sliceStringToFitScreen(cleanedString);
}

const HandleExit =(()=>{
  console.log("hi exit from model")
  // navigate(`${userRoute}/InboxAllEmails`,{state:receivedData}
  setIsActive(isActive)
  setAllMails(null)
})


    useEffect(() => {
      // Update receivedData if location.state changes
      if(allEmailsData)
      setReceivedData(allEmailsData);
    }, [allEmailsData]);

    const handleTabs =((tab)=>{
      if(tab=="Unread"){
        handleGetEmail('','unread')
        setIsActive('Unread');
      }
      if(tab=="Read"){
        setIsActive('Read');
        handleGetEmail('','read')
      }
      if(tab=="All"){
        handleGetEmail('', "All")
        setIsActive('All');
      }

      if(tab=="Others"){
        setIsActive('Others');
      }
      if(tab=="Replies"){
        setIsActive('Replies');
      }
      if(tab=="Leads"){
        setIsActive('Leads');
      }
      if(tab=="Unofficial"){
        setIsActive('Unofficial');
      }
      

      
    })
  return (
    
    
    <div className='email_inbox_outer'>
       {messeges !== null ? (
        <AllMessegesModel allEmailsData={allEmailsData} messages={messeges} receivedData={receivedData}  setMesseges={setMesseges} isActive={isActive} setIsActive={setIsActive}/>
      ) : (
        <>
        <Button  onClick={HandleExit} >
    <GoChevronLeft size={24}/>
    </Button>
    <Divider/>
        <ButtonGroup className='email_filter_btn' marginLeft={10} marginTop={5} colorScheme='blue'  size='sm' variant='outline' spacing='6'>
         <Button size='sm' onClick={()=>handleTabs('Unread')} className={isActive=='Unread'? 'active': ''}>Unread</Button>
         <Button size='sm' onClick={()=>handleTabs('Read')} className={isActive=='Read'? 'active': ''}>Read</Button>
         <Button size='sm' onClick={()=>handleTabs('All')} className={isActive=='All'? 'active': ''}>All</Button>
         <Button size='sm' onClick={()=>handleTabs('Others')} className={isActive=='Others'? 'active': ''}>Others</Button>
         <Button size='sm' onClick={()=>handleTabs('Replies')} className={isActive=='Replies'? 'active': ''}>Replies</Button>
         <Button size='sm' onClick={()=>handleTabs('Leads')} className={isActive=='Leads'? 'active': ''}>Leads</Button>
         <Button size='sm' onClick={()=>handleTabs('Unofficial')} className={isActive=='Unofficial'? 'active': ''}>Unofficial</Button>
            </ButtonGroup>
      <List spacing={3} my={8} className='email_list_box'>
     
        {receivedData?
            <Skeleton isLoaded={!isLoading}>
            {receivedData.emails?.map((item, index) => {
              const { subject, snippet } = item.messages[0];
              const {from} = item.messages[item.messages.length-1];
              const status = item.messages.some(message => message.status === "unread") ? "unread" : "read";
              const {date} = item.messages[item.messages.length -1]
              // const time = extractTime(date);
              const time = date;
            return (
                <>
                
                  <Divider/>
                <ListItem key={index} display={'flex'} justifyContent={'space-between'} my={4} mx={'20px'} onClick={()=>hanldeTextSubject(item.messages)}>
                <HStack className='left_emailside'>
                    {/* <ListIcon as={MdMailOutline} color="green.500" /> */}
                    <Checkbox ></Checkbox>
                    <Box className='list_row'>
                    <Text  className={status=='unread' ? 'list_item_t':'list_item_unread subject_desc'} ><div className='subject_name'><span className="from_span">  {removeContentBetweenBrackets(from)} </span> <span className='email_tag'><strong> {subject}</strong> - <span className="snippet_span">  {processString(snippet)}</span></span></div></Text>
                    
                    </Box>
                </HStack> 
                <HStack className='right_emailside'>
                      <Text className={status=='unread' ? "time-display":"time-display-unread message_time"}>{time}</Text>
                    </HStack>
                <HStack>
                    {/* <ListIcon as={MdSettings} color="green.500" /> */}
                    {/* <ListIcon as={MdMenu} color="green.500" /> */}
                </HStack> 
                </ListItem>
                <Divider/>
                </>
            );
            })}
            </Skeleton> : 
            <Skeleton height={'100px'}/>
        }
        
      </List>
      
      <GoChevronRight
      className='Right-next-page'
      size={24}
      onClick={() => handleGetEmail(receivedData.nextPageToken,'')}
      />
      </>
      )}
    </div>
  
  )
}

export default InboxAllEmails