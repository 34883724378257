import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ mainPath,component: Component, ...rest }) => {
    console.log('mainPath',mainPath);
    
    
    console.log(".....g .",Component)
    console.log("rest...",rest)
    let auth = localStorage.getItem("userEmail");
    
    return auth ? <Component mainPath={mainPath} {...rest} /> : <Navigate to="/login" />;
}

export default ProtectedRoutes;
