import { Divider,Button } from '@chakra-ui/react';
import React from 'react'
import { GoChevronLeft } from "react-icons/go";
import {MdArchive} from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text
  } from '@chakra-ui/react'
  import './InboxCardModel.css'
  import InboxAllEmails from '../Table/InboxAllEmails';

const AllMessegesModel = ({allEmailsData,messages,receivedData,setMesseges, isActive, setIsActive}) => {

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
    const navigate =useNavigate()
    const routesConfig = [
        { path: '/admin', role: 'Admin' },
        { path: '/creator', role: 'Creator Manager' },
        { path: '/influencer', role: 'Influencer' },
      ];
    
      // Get the user's role from Redux or localStorage
      const role = useSelector((state) => state.role) || localStorage.getItem('Role');
    
      // Find the path based on the user's role
      const userRoute = routesConfig.find((route) => route.role === role)?.path;
    
      console.log('userroutes',userRoute)
    
    const archiveMessage = async ()=>{
      try {
        const response = await fetch(BASE_URL + "/archieve-message/", {
          method: "POST",
           body: JSON.stringify({
            "inbox_email":"mukesh@creativesmatter.com",
            "message_id":"191fade699650457"
        }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        
        const data = await response.json();
          console.log("inbox daat>>",data);
          
        if (response.status === 401) {
          
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        if(response.status === 200){
         
          return 
  
        }
      } catch (error) {
   
        
        console.error("Error:", error);
      }
    }

    const HandleExit =(()=>{
        console.log("hi exit from model")
        // navigate(`${userRoute}/InboxAllEmails`,{state:receivedData})
        setMesseges(null)
        setIsActive(isActive)
    })

  return (
    <div>
      {messages === null ?
      (<InboxAllEmails allEmailsData={allEmailsData} />):
    (
            
      <>
      <div className='back_arrow_design'>
        <div className='back_handel'>
        <Button  onClick={HandleExit} >
         <GoChevronLeft size={24}/>
        </Button>
        <Text className='all_emails_main_text'> {messages[0].subject} </Text> </div>
     <MdArchive  
     //onClick={archiveMessage}
     /></div>
     

        <Accordion defaultIndex={[0]} allowMultiple className='acc_main_top'>
        {messages.map((message)=>{
            return (
                
  <AccordionItem className='acc-item-main'>
    <h2>
      <AccordionButton pb={7}>
       
       
        <Box as='span' flex='1' textAlign='left' style={{ fontWeight: 'bold' }}>
          {message.from}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={6}>
    {/* Use dangerouslySetInnerHTML to render HTML */}
    <Box dangerouslySetInnerHTML={{ __html: message.snippet }} />
    </AccordionPanel>
   
  </AccordionItem>
  ) })}



</Accordion>
      </>      
    )
  }
    </div>
  )
}

export default AllMessegesModel