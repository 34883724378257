
import { Box, Card, CardBody, Flex, Stack, Text, Input, useColorMode, useToast } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { MdFileUpload } from 'react-icons/md';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import Question from './Question';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import ContractModal from '../Modal/ContractModal';
import { Spinner } from '@chakra-ui/react'
import "./Invoice.css"
import Past_Preview from './Past_Preview';

const Contract = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const { colorMode } = useColorMode();
  const toast = useToast();
  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state
  const[Review,setReview] = useState("")
  const[show,setShow] = useState(false)
  const[FileName,setFileName] = useState("")
  const[isOpenContractModal,setIsOpenContractModal]=useState(false)
  const [flag,setFlag] =useState(false)



  let guess =(function(props) {
    // Code that runs immediately
    console.log(props)
})();


const handleFileUpload = async (file) => {
  console.log("file -->", file);
  if (!file) return;

  setIsLoading(true);
  const formData = new FormData();
  formData.append('file', file);
  console.log("form data--> ", formData);

  try {
    const response = await fetch(`${BASE_URL}/reviews/`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${token}`, // If you need to pass a token
      },
    });

    const data = await response.json();
    console.log(response.status, "respstatus");

    if (response.ok) { // response.ok is true for status 200-299
      setReview(data.review.response);
      setFlag(!flag);
      toast({
        title: "File uploaded",
        description: "Your file has been uploaded successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      console.log(data);
    } else if (response.status === 400) {
      toast({
        title: "File not uploaded",
        description: "Unsupported file extension.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } 
    else {
      toast({
        title: "Upload failed",
        description: "There was an error uploading your file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  } catch (error) {
    toast({
      title: "Upload failed",
      description: "An unexpected error occurred. Please try again.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    console.error("Error uploading file:", error);
  } finally {
    setIsLoading(false);
  }
};



  useEffect(()=>{
    if(Review) {
      setShow(true)
      setIsOpenContractModal(true)
    }

  },[Review])
         
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      handleFileUpload(file);
      toast({
        title: "File selected",
        description: `${file.name} (${formatFileSize(file.size)})`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Reset the file input value
      fileInputRef.current.value = null;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      setFileName(file.name)
      setSelectedFile(file);
      handleFileUpload(file);
      toast({
        title: "File dropped",
        description: `${file.name} (${formatFileSize(file.size)})`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
  };
  const backgroundColor = isDragging || isLoading 
  ? (isLoading ? '#f5f5dc' : '#e0e7ff') 
  : 'transparent';

  return (
    <Box>
      <Tabs>
        <TabList>
          <Tab>Add Contract</Tab>
          <Tab>Past Reviews</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <Flex alignItems={"center"} justifyContent={"center"} height={"calc(80vh - 60px)"}>
                <Card direction={{ base: 'column', sm: 'row' }} overflow='hidden' variant='outline'>
                  <Stack>
                    <CardBody>
             
                      <Box
                        border="2px dashed #3a61f0"
                        borderRadius="10px"
                        p="130px 250px"
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        backgroundColor={backgroundColor}
                      >     
    
                        <Flex flexDirection={"column"} alignItems={"center"}>
                        {  isLoading ? (<Box className='spinerr'>
         <Spinner
               thickness='5px'
               speed='0.40s'
               emptyColor='blue.50'  // Lighter shade of blue
               color='blue.500' 
               size='xl'
                           /> 

                            </Box>)  :
                       
                          <MdFileUpload  style={{ cursor: 'pointer' }} size={70}  onClick={() => fileInputRef.current.click()} />  }

                          {!isLoading ?
                          <Text textColor={"black"} fontSize={"14px"} mt={"5px"}>Drag and drop a .pdf, .docx file for review</Text>
                            : <Text fontSize={"15px"} mt={"83px"}>Loading....</Text>}
                          
           
                          <Input
                            type='file'
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            accept=".pdf,.doc,.docx"
                         
                          />
                          
                        </Flex>
                      </Box>
                    </CardBody>
                  </Stack>
                </Card>
              </Flex>
            </Box>
          </TabPanel>
          <TabPanel>
            
            <Past_Preview
            flag={flag}
            setFlag={setFlag}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    
     

      <ContractModal 
      isOpen={isOpenContractModal}
      onClose={() => {setIsOpenContractModal(false);}}
      Review={Review}
      FileName={FileName}
      />
    </Box>

  );
};

export default Contract;

