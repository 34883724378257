import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import NavigationPanel from '../components/Panel/NavigationPanel'
import TopPanel from '../components/Panel/TopPanel'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { logDOM } from '@testing-library/react'

const AdminPage = ({mainPath}) => {
    console.log('mainPathfrom Admin page>>>',mainPath);
    
    const location = useLocation()
    console.log('location>>>',location);
    
    return (
        <Flex>
            <NavigationPanel  mainPath={mainPath} />
            <Box flex='1'>
                <TopPanel />
                <Outlet />
                {/* <AlertDialogBox /> */}
                {/* <Flex height={"calc(100vh - 60px)"} alignItems={"center"} justifyContent={"center"} fontWeight={"500"} fontSize={"20px"}>Hold steady, enhancements are in progress !!!</Flex> */}
            </Box>
        </Flex>
    )
}

export default AdminPage