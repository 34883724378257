import React, { useEffect, useMemo, useState } from 'react'
import { COMPANY_COLUMNS } from './Columns';
import { useTable, usePagination, useRowSelect } from 'react-table';
import { Box, Button, Flex, Input, InputGroup, InputLeftElement, InputRightElement, SkeletonText, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useColorMode, useToast } from '@chakra-ui/react';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';
import { LuDatabaseBackup } from "react-icons/lu";
import Checkbox from './Checkbox';
import { IoMdTrash } from 'react-icons/io';
import { BsBuildingFillAdd } from "react-icons/bs";
import { AddIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { VscEye } from 'react-icons/vsc';
import { format } from "date-fns";
import CompanyFormModal from '../Modal/CompanyFormModal';
import ProspectModal from '../Modal/ProspectModal';
import CompanyDeleteDialogBox from '../Modal/CompanyDeleteDialogBox';
import { useNavigate } from 'react-router-dom';


const CompanyTable = () => {

    // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
    const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
    const token = localStorage.getItem('token');

    
    const toast = useToast();
    const { colorMode } = useColorMode();
    const navigate = useNavigate()


    const columns = useMemo(() => COMPANY_COLUMNS, []);
    const [isLoading, setIsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [companyData, setCompanyData] = useState([]);
    const [prospectData, setProspectData] = useState([]);
    //console.log("prospectData>>>>",prospectData)
    const [companyID, setCompanyID] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [isOpenProspectModal, setIsOpenProspectModal] = useState(false);
    const [isOpenCompanyFormModal, setIsOpenCompanyFormModal] = useState(false);
    const [isOpenCompanyDeleteDialogBox, setIsOpenCompanyDeleteDialogBox] = useState(false);
    const [isEditing, setIsEditing] = useState(null);
    const[editingCell,setEditingCell] = useState (null);
    const [lastTapTime, setLastTapTime] = useState(0);
    const [editedRowData, setEditedRowData] = useState(null);
    const [filterValue, setFilterValue] = useState("");
    const [updateProspect, setUpdateProspect] = useState(false)
    //console.log("setUpdateProspect>>",updateProspect)
    const [targetProspectId, setTargetProspectId] = useState("")
    //console.log("targetProspectId>>>>>>",targetProspectId)
    const [updateEmail, setUpdateEmail] = useState(false)
    //console.log("updateEmail>>",updateEmail)
    const [targetCompanyId, setTargetCompanyId] = useState("")
    const[prospectDelete,setProspectDelete]= useState(false)
//console.log("targetprospectID",targetCompanyId)
    const { 
        getTableProps, 
        getTableBodyProps, 
        prepareRow, 
        headerGroups, 
        page, 
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage, 
        nextPage,
        selectedFlatRows,
    } = useTable({
        columns,
        data: companyData,
        initialState: { pageIndex: 0, pageSize: 15 },
        manualPagination: true,
        pageCount,
    },
    // useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
        hooks.visibleColumns.push((columns) => {
            return [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <Checkbox { ...getToggleAllRowsSelectedProps() } />
                    ),
                    Cell: ({ row }) => (
                        <Checkbox  { ...row.getToggleRowSelectedProps() } />
                    )
                },
                ...columns
            ]
        })
    });

    const fetchCompanyData = async () => {
        //console.log("hello fetchhhh")
        try {
            const response = await fetch(BASE_URL + (filterValue ? `/companies/?search=${filterValue}&page=${pageIndex + 1}&page_size=${pageSize}` : `/companies/?page=${pageIndex + 1}&page_size=${pageSize}&ordering=-created_ts`),{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`
                }
            });    
            const data = await response.json();
            if (data.code === 'token_not_valid') {
                localStorage.removeItem("userEmail");
                localStorage.removeItem("token");
                navigate('/login');
            }
            //console.log("data>>>",data)
            let matchingCompany;
            if(updateProspect || targetProspectId){
                matchingCompany = data?.companies_data?.find(company => 
                   company?.prospects?.some(prospect => prospect.prospect_id === targetProspectId)
                 );
   
                 //console.log("matchingCompany>>>>>>",matchingCompany)
                
               }
                    if(updateProspect || targetProspectId || updateEmail){
                        //console.log("here>>>>>12>>>>")
                    matchingCompany = data?.companies_data?.find(company => 
                        company?.prospects?.some(prospect => prospect.prospect_id === targetProspectId)
                    );
                            // console.log("Mtaching companyyyy>>>>", matchingCompany)
              //console.log("matchingCompany>>>>>>",matchingCompany)
             
            }
            if(matchingCompany){
                setProspectData(matchingCompany?.prospects);
            }
            // setProspectData(data?.companies_data[0].prospects);
            setCompanyData(data?.companies_data);
            setPageCount(data?.paginator?.total_pages);
            setIsLoading(false);
        }
        catch (error) {
            // setIsLoading(false);

            toast({
                title: 'Fetch Error.',
                description: 'Failed to fetch company details',
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })

            console.error('Error:', error);
        };
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchCompanyData();
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [pageIndex, pageSize, filterValue]);


   





    const handleRowClick = (row) => {

        //console.log("calls from emails model >>",row)
        setCompanyID(row?.original?.company?.company_id);
        setCompanyName(row?.original?.company?.company_name);
        // console.log(row);
        // console.log(row?.original?.company?.company_id);
        setProspectData(row?.original?.prospects);
        setIsOpenProspectModal(true);
        setUpdateEmail(false)
        setUpdateProspect(false)
    }
  


    useEffect(() => {
       
        if(updateProspect){
            console.log("hello prospect UPDATE FROM COMPANY PAGE")
            fetchCompanyData();
        }
     }, [updateProspect]);


     useEffect(() => {
        console.log("hello email")
        if(updateEmail){
            fetchCompanyData();
        }
     }, [updateEmail]);


     useEffect(() => {
        //console.log("hello delete")
        if(prospectDelete){
            fetchCompanyData();
            
        }
     }, [prospectDelete]);

     

    const handleDeleteRows = async (selectedFlatRows) => {
        try {
            setIsLoading(true);
            const companyIds = selectedFlatRows.map(row => row?.original?.company.company_id);
            const response = await fetch(BASE_URL + '/del_companies/', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`
                },
                body: JSON.stringify({ company_ids: companyIds })
            });
                    const data =await response.json();
                    console.log('data', data);
                    
                if (response.ok) {
                    setIsLoading(false);
                    // setCompanyData(prevData => prevData.filter(company => company.company_id !== companyId));
                    setCompanyData(prevData => prevData.filter(company => !companyIds.includes(company.company_id)));
                    fetchCompanyData();
                    toast({
                        title: 'Company Deletion Status',
                        description: `${data.message}`,
                        status: 'success',
                        position: 'bottom',
                        duration: 3000,
                        isClosable: true,
                    });

                    setIsOpenCompanyDeleteDialogBox(false);
                }
                if (response.status === 401) { 
                    localStorage.removeItem("userEmail");
                    localStorage.removeItem("token");
                    navigate('/login');
                    return; 
                } 
        } 
        catch (error) {
            toast({
                title: 'Deletion Failed',
                description: 'Failed to delete company details.',
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
            setIsOpenCompanyDeleteDialogBox(false);
            console.error('Error:', error);
        }
    };

    // const handleEditRow = () => {
    //     setIsEditing(selectedFlatRows[0]?.original?.company?.company_id);
    //     setEditedRowData(selectedFlatRows[0]?.original?.company);
    // }

    const handleCellBlur = () => {
        // Auto-save when clicking outside the cell
        if (isEditing  ) {
            setIsEditing(null);
            setEditingCell(null);
          handleSaveRow();
        }
      };

    const handleCellClick = (row, cell) => {
        // Check if the cell is editable
       
            
        if (cell.column) {  
          setIsEditing(row.original.company?.company_id);
          setEditedRowData([row.original.company]);
          setEditingCell(cell.column.id);
        }
        
      };

    const handleEditRow = () => {
        const selectedEditableCompanyIds = selectedFlatRows?.map((row) => row.original.company?.company_id);
        setIsEditing(selectedEditableCompanyIds);
        const selectedRowData = selectedFlatRows?.map((row) => row.original.company);
        setEditedRowData(selectedRowData);
      };

      useEffect(()=>{
        // handleEditRow()
        setIsEditing(null)
        setEditedRowData(null)
      },[])
    
    const handleSaveRow = async () => {
        // const companyId = editedRowData?.company_id;
        const payload = {
            company_data: editedRowData
        }
        
        try {
            const response = await fetch(BASE_URL + `/update_companies/`, { //http://127.0.0.1:8000/update_companies/
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`
                },
            });
        
            if (response.ok) {
                setIsEditing(null);
                setEditedRowData(null);
                fetchCompanyData();
                toast({
                    title: 'Company Updated',
                    description: 'Company updated successfully.',
                    status: 'success',
                    position: 'bottom',
                    duration: 3000,
                    isClosable: true,
                });
            }
            
            if (response.status === 401) {
                localStorage.removeItem("userEmail");
                localStorage.removeItem("token");
                navigate('/login');
                return; 
            } 
        } 
        catch (error) {
            toast({
                title: 'Update Failed',
                description: 'Failed to update company details.',
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            });
            console.error('Error:', error);
        }
    }
    
    

    const handleHernricRow = async(selectedFlatRows)=>{
        //console.log("isEditing>>>>>>",selectedFlatRows)
        // e.preventDefault();
        const companyIds = selectedFlatRows.map(row => row?.original?.company.company_id);
     
        
        try {
            const response = await fetch(BASE_URL + `/enrich/`, { //http://127.0.0.1:8000/update_companies/
                method: 'POST',
                body: JSON.stringify({ company_ids: companyIds }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : `Bearer ${token}`
                }
            });
        
            if (response.ok) {
                setIsEditing(null);
                setEditedRowData(null);
                fetchCompanyData();
              
                toast({
                    title: 'Company Updated',
                    description: 'Company updated successfully.',
                    status: 'success',
                    position: 'bottom',
                    duration: 3000,
                    isClosable: true,
                });
            }
            if (response.status === 401) {
                localStorage.removeItem("userEmail");
                localStorage.removeItem("token")
                navigate('/login');
                return; 
            } 
        } 
        catch (error) {
            toast({
                title: 'Update Failed',
                description: 'Failed to update company details.',
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            });
            console.error('Error:', error);
        }

        

    }




    return (
        <Box px={5} py={3}>
            <SkeletonText noOfLines={20} spacing='2' skeletonHeight='6' isLoaded={!isLoading}>
                {
                    selectedFlatRows.length > 0 ?
                    <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
                        <Box>{ selectedFlatRows.length } selected</Box>
                        <Flex alignItems={"center"} gap={3}>
                            {
                                isEditing !== null ?
                                <Button onClick={handleSaveRow} size={"sm"} colorScheme='blue'>Save</Button> :
                                <>
                                <Button onClick={()=>handleHernricRow(selectedFlatRows)} size={"sm"} colorScheme='orange'><LuDatabaseBackup size={20} color="white"/></Button>
                                {/* <Button onClick={handleEditRow} size={"sm"} colorScheme='yellow'>Edit</Button> */}
                                </>
                            }
                            {
                                !isEditing &&
                                <Button onClick={() => setIsOpenCompanyDeleteDialogBox(true)} size={"sm"} colorScheme="red" variant="solid"><IoMdTrash size={20} color="white"/></Button>
                            }
                        </Flex>
                    </Flex>
                    :
                    <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
                        <Box>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <SearchIcon color='gray.400' />
                                </InputLeftElement>
                                <Input type='text' placeholder='Search company...' width={"350px"} borderRadius={"100vh"} value={filterValue} onChange={(e) => { setFilterValue(e.target.value); gotoPage(0); }} />
                                {
                                    filterValue && (
                                        <InputRightElement cursor={"pointer"}>
                                            <CloseIcon w={3} h={3} color='gray.400' onClick={() => setFilterValue("")} />
                                        </InputRightElement>
                                    )
                                }
                            </InputGroup>
                        </Box>
                        <Button onClick={() => setIsOpenCompanyFormModal(true)} size={"sm"} colorScheme='blue' variant='solid'><BsBuildingFillAdd size={20} color="white"/></Button>
                    </Flex>
                }
                <Box fontSize={14} mb={1} boxShadow='xs' h={"calc(100vh - 180px)"} w={"calc(100vw - 290px)"} rounded={"md"} sx={{
                    position: 'relative'
                }}>
                    <TableContainer {...getTableProps()} sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflowY: 'scroll',
                        "&::-webkit-scrollbar": { height: "10px", width: "0px" },
                        "&::-webkit-scrollbar-track": { background: "#f1f1f1", borderRadius: "10px" },
                        "&::-webkit-scrollbar-thumb": { background: "#3182ce", borderRadius: "10px" },
                    }}>
                        <Table variant='simple' width={"100%"}>
                            <Thead bg={"green.400"}  className='fix-header'>
                                {headerGroups?.map(headerGroup => (
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                                                <Flex alignItems={"center"}>
                                                    {column.render('Header')}
                                                    <Box>{column.isSorted ? (column.isSortedDesc ? <HiArrowDown /> : <HiArrowUp />) : ''}</Box>
                                                </Flex>
                                            </Th>
                                        ))}
                                        <Th color={"#FFFFFF"}>Prospects</Th>
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody {...getTableBodyProps()}>
                                {page?.map(row => {
                                    prepareRow(row);
                                    // const isRowEditing = isEditing === row?.original?.company?.company_id;
                                    const isRowEditing = isEditing?.includes(row?.original?.company.company_id);
                                
                                    return (
                                        <Tr {...row.getRowProps()} _hover={{ bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px" }}>
                                            {row?.cells.map(cell => {
                                                const isCellEditing = isRowEditing && editingCell === cell.column.id;
                                                return (
                                                    <Td
                                                    onTouchEnd={(e) => {
                                                        // Check if the touch event is a double-tap
                                                        if (e.timeStamp - lastTapTime < 600) {
                                                            handleCellClick(row, cell);
                                                        }
                                                        setLastTapTime(e.timeStamp);
                                                      }}
                                                    onDoubleClick={() => handleCellClick(row, cell)} // Handle cell click
                                                    {...cell.getCellProps()}>
                                                        {
                                                            cell.column.id === 'selection' ? (
                                                                <Checkbox {...row.getToggleRowSelectedProps()} disabled={isRowEditing} />
                                                            ) 
                                                            : cell.column.Header === 'Created At' ? (
                                                                format(new Date(row.original.company.created_ts), 'MM/dd/yyyy HH:mm:ss') 
                                                            )  : 
                                                            isCellEditing ? (
                                                                <Textarea
                                                                style={{ resize: "both", minWidth: "250px"}}
                                                                // w="250px"
                                                                    type="text"
                                                                    value={row.values[cell.column.id]}
                                                                    // onChange={(e) => {
                                                                    //     const fieldName = cell.column.id;
                                                                    //     const edittedField = fieldName.split(".")[1];
                                                                    //     row.values[fieldName] = e.target.value;
                                                                    //     setEditedRowData({ ...editedRowData, [edittedField]: e.target.value });
                                                                    // }}
                                                                    onChange={(e) => {
                                                                        const fieldName = cell.column.id;
                                                                        const edittedField = fieldName.split(".")[1];
                                                                        const updatedValue = e.target.value;
                                                                        const updatedCompanyId = row.original.company.company_id;
                                                                        row.values[fieldName] = updatedValue;
                                                                        // Create a new array with updated data for the corresponding prospect
                                                                        const updatedEditedRowData = editedRowData?.map((rowData) => {
                                                                          if (rowData.company_id === updatedCompanyId) {
                                                                            return {
                                                                              ...rowData,
                                                                              [edittedField]: updatedValue,
                                                                            };
                                                                          }
                                                                          return rowData;
                                                                        });
                                                                      
                                                                        setEditedRowData(updatedEditedRowData);
                                                                      }}
                                                                      onBlur={() => handleCellBlur()} // Auto-save on input blur
                                                                />
                                                            )   :
                                                            (
                                                                cell.render('Cell')
                                                            )
                                                            
                                                        }
                                                    </Td>
                                                );
                                            })}
                                            <Td>
                                                <Flex gap={2}>
                                                    <Button leftIcon={<VscEye size={16} />} variant='outline' colorScheme="pink" size="sm" onClick={() => {handleRowClick(row)}} >View ({row?.original?.prospects?.length})</Button>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
                {/* <pre>
                    <code>
                        {
                            JSON.stringify({
                                selectedFlatRows: selectedFlatRows.map(row => row.original)
                            }, null, 2)
                        }
                    </code>
                </pre> */}
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                    <Flex>Page&nbsp;<Text fontWeight={500}>{pageIndex + 1} of {pageCount}</Text></Flex>
                    <Flex alignItems={"center"} gap={3}>
                        <Box _hover={{ bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer", borderRadius: "100vh" }} p={"7px"}>
                            <GoChevronLeft size={24} onClick={() => previousPage()} disabled={pageIndex === 0} />
                        </Box>
                        <Box _hover={{ bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer", borderRadius: "100vh" }} p={"7px"}>
                            <GoChevronRight size={24} onClick={() => nextPage()} disabled={pageIndex === pageCount - 1} />
                        </Box>
                    </Flex>
                </Flex>
        </SkeletonText>

        <CompanyFormModal 
            isOpen={isOpenCompanyFormModal}
            onClose={() => { 
                setIsOpenCompanyFormModal(false);
                fetchCompanyData();
            }}
        />

        <ProspectModal 
            isOpen={isOpenProspectModal}
            onClose={() => { 
                setIsOpenProspectModal(false);
                fetchCompanyData();
            }}
            prospectData={prospectData}
            companyID={companyID}
            companyName={companyName}
            handleRowClick={handleRowClick}
            fetchCompanyData={fetchCompanyData}
            setUpdateProspect={setUpdateProspect}
            setIsOpenProspectModal={setIsOpenProspectModal}
            updateProspect = {updateProspect}
            setTargetProspectId={setTargetProspectId}
            setUpdateEmail = {setUpdateEmail}
            updateEmail = {updateEmail}
            setProspectDelete={setProspectDelete}
            setProspectData={setProspectData}
        />

        <CompanyDeleteDialogBox
            isOpen={isOpenCompanyDeleteDialogBox}
            onClose={() => {
                setIsOpenCompanyDeleteDialogBox(false);
            }}
            isLoading={isLoading}
            selectedFlatRows={selectedFlatRows}
            handleDeleteRows={handleDeleteRows}
        />
    </Box>
    )
}

export default CompanyTable