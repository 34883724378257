import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
import React from 'react'
import { PulseLoader } from 'react-spinners'

const CampaignsRejectDialogBox = ({ isOpen, onClose, selectedRejectRows, isLoading, handleRejectRows }) => {
        return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>Reject Campaign</AlertDialogHeader>
                <AlertDialogBody>Are you sure you want to reject the selected campaigns?</AlertDialogBody>
                <AlertDialogFooter>
                    <Button size={"sm"} onClick={onClose}>Cancel</Button>
                    <Button size={"sm"} colorScheme='red' isLoading={isLoading} spinner={<PulseLoader size={8} color='white' />} loadingText="Rejecting" spinnerPlacement='end' onClick={() => handleRejectRows(selectedRejectRows)} ml={3}>Reject</Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default CampaignsRejectDialogBox